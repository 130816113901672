import gql from "graphql-tag";

import { getDefaultLayout } from "../../../utils/defaultLayout";
import { PIE_COLORS } from "../../../utils/colors";

const labels = ["Fee < 2%", "Fee < 2.1%", "Fee < 2.5%", "Fee >= 2.5%"];

const fundMgmtFeeByVintage = (
  feeType: "numFundsByIPManagementFee" | "numFundsByPostIPManagementFee"
): PlotSpec => ({
  query: gql`
    query fundFeesByVintage(
      $filters: fundsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      funds(
        dimensions: ["vintageYear"]
        filters: $filters
        sorted: true
        removeCensored: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        vintageYear
        f0: ${feeType}(from: null, to: 0.02)
        f1: ${feeType}(from: 0.02, to: 0.021)
        f2: ${feeType}(from: 0.021, to: 0.025)
        f3: ${feeType}(from: 0.025, to: null)
        datasetInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => {
    // For this stacked area chart, we need to calculate the cum sum manually for each year
    let prevVal = Array<number>(data.funds.vintageYear.length).fill(0);
    const traces = labels.map((lbl, lblIdx) => {
      // Cum sum
      const val = data.funds[`f${lblIdx}`].map(
        (elt: number, idx: number) => elt + prevVal[idx]
      );
      prevVal = val;

      // @ts-ignore
      return {
        type: "scatter",
        x: data.funds.vintageYear,
        y: val,
        fill: lblIdx === 0 ? "tozeroy" : "tonexty",
        hovertext: data.funds[`f${lblIdx}`],
        hoverinfo: "x+name+text",
        mode: "lines",
        name: lbl,
        marker: {
          color: PIE_COLORS[lblIdx],
        },
      } as Plotly.Data;
    });

    return {
      data: traces,
      layout: {
        ...getDefaultLayout("year", "Number of Funds"),
        legend: {
          ...getDefaultLayout("year").legend,
          traceorder: "normal",
        },
      },
    };
  },
  censoringChecker: (data) => data.funds.datasetInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Year"].concat(labels),
    data: [
      data.funds.vintageYear,
      data.funds["f0"],
      data.funds["f1"],
      data.funds["f2"],
      data.funds["f3"],
    ],
  }),
});

export const fundIPMgmtFeeByVintage = fundMgmtFeeByVintage(
  "numFundsByIPManagementFee"
);
export const fundPostIPMgmtFeeByVintage = fundMgmtFeeByVintage(
  "numFundsByPostIPManagementFee"
);
