export type TFilterNames =
  | "vintageYear"
  | "sectorGroup"
  | "stageGroup"
  | "teamLocation"
  | "investmentYear"
  | "investmentSectorGroup"
  | "investmentStageGroup"
  | "investmentCountry"
  | "countryGroup"
  | "sector"
  | "stageFocus"
  | "year";
export type TFilterCategories = "FundsData" | "InvestmentData" | "VcSurveyData";

export interface IFiltersData {
  data: IFilters[];
}

export interface IFilter {
  label: string;
  __typename: TFilterCategories;
  values: string[];
}

export interface IFilters {
  [key: string]: IFilter;
}

export const vcSurveyTopics = [
  "State of Business",
  "Fundraising Environment",
  "Easiness to Find Co-Investors",
  "Investment Proposals",
  "New Investments",
  "Portfolio Development",
  "Exit Opportunities",
] as const;
export type VCSurveyTopic = typeof vcSurveyTopics[number];

export type DatasetID = "FundsData" | "InvestmentData" | "VcSurveyData";

export interface IPlotParameters {
  filtersData: {
    [T in DatasetID]: IFilters;
  };
  selectedFilters: {
    [key: string]: string[];
  };
  selectedPortfolioOnly: boolean;
  vcSurveyTopic: VCSurveyTopic;
}

export interface ISetSelectedFilter {
  filterName: string;
  filterValue: string;
}

export interface IChosenPlotParameters {
  chosenFilters: Pick<
    IPlotParameters,
    "selectedFilters" | "selectedPortfolioOnly"
  >;
  vcSurveyTopic: IPlotParameters["vcSurveyTopic"];
}
