import React from "react";

import "./Disclaimer.scss";
import { TermsOfUse, PrivacyNotice, CookiePolicy } from "./content";
import { Button } from "../Button";

interface IDisclaimerProps {
  onSubmit: () => void;
  content: "TermsOfUse" | "CookiePolicy" | "PrivacyNotice";
}

const Disclaimer = ({ onSubmit, content }: IDisclaimerProps) => {
  const Content =
    content === "TermsOfUse"
      ? TermsOfUse
      : content === "PrivacyNotice"
      ? PrivacyNotice
      : CookiePolicy;

  return (
    <div className="License">
      <div className="License__content">
        <Content />
      </div>
      <Button size="lg" success onClick={onSubmit}>
        I Agree
      </Button>
    </div>
  );
};

export default Disclaimer;
