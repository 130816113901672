import {
  createStore,
  applyMiddleware,
  combineReducers,
  AnyAction,
  Store,
} from "redux";
import thunkMiddleware, { ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { sessionReducer, SessionState } from "./data/session";
import { filtersReducer, FiltersState } from "./data/filters";
import { disclaimerReducer, DisclaimerState } from "./data/disclaimers";

export interface RootState {
  session: SessionState;
  filters: FiltersState;
  disclaimers: DisclaimerState;
}

const reducers = combineReducers<RootState>({
  session: sessionReducer,
  filters: filtersReducer,
  disclaimers: disclaimerReducer,
});

type MyDispatch = ThunkDispatch<RootState, {}, AnyAction>;
export type AppDispatch = (Store<RootState, AnyAction> & {
  dispatch: MyDispatch;
})["dispatch"];

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware<MyDispatch, RootState>(thunkMiddleware))
);
