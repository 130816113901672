import { useState, useEffect, useRef } from "react";

export const useOutsideClick = () => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [isOpen, openHandler] = useState(false);

  useEffect(() => {
    isOpen
      ? document.addEventListener("click", handleClickOutside, true)
      : document.removeEventListener("click", handleClickOutside, true);
  });

  const handleClickOutside = (event: any) => {
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      openHandler(!isOpen);
    }
  };

  return { nodeRef, isOpen, openHandler };
};
