import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors } from "../../../../data/filters";
import { RootState } from "../../../../store";
import { BenchmarkingPlotGQL } from "../../../../components/BenchmarkingPlot";
import { Header } from "../../../../components/Header";

import withScrollingToComponent, {
  IComponentReferences,
} from "../../../../utils/HOC/withScrollingToComponent";
import { PlotTabInnerProps } from "../types";

function mapStateToProps(state: RootState) {
  return selectors(state);
}

class FundRaising extends Component<PlotTabInnerProps> {
  private readonly componentReferences: IComponentReferences;

  constructor(props: PlotTabInnerProps) {
    super(props);
    this.componentReferences = {
      fundsVintage: React.createRef(),
      fundsCountry: React.createRef(),
      investorGroupsCommitment: React.createRef(),
    };

    props.setReferences(this.componentReferences);
  }

  render() {
    const { chosenFilters } = this.props;
    return (
      <div className="Explorer__graphs-container">
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundsVintage}
        >
          <Header text="EIF Commitment by Vintage" displayIcon size="xl" />
          <BenchmarkingPlotGQL
            plotID="fundCommitByVintage"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundsCountry}
        >
          <Header
            text="EIF Commitment by Team Location"
            displayIcon
            size="xl"
          />
          <BenchmarkingPlotGQL
            plotID="fundCommitByCountry"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.investorGroupsCommitment}
        >
          <Header
            text="Total Commitment by Investor Group"
            displayIcon
            size="xl"
          />
          <BenchmarkingPlotGQL
            plotID="fundInvestorGroups"
            filters={chosenFilters}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withScrollingToComponent(FundRaising));
