export const setItem = (
  name: string,
  value: string | boolean,
  expireInDays: number = 30
) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + expireInDays);

  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
};

export const getItem = (name: string) => {
  const pair = document.cookie.match(new RegExp(name + "=([^;]+)"));
  return !!pair ? pair[1] : null;
};
