import React, { Component, RefObject } from "react";
import { connect } from "react-redux";
import {
  clearFilters,
  filterSelector,
  IFilter,
  IPlotParameters,
  setActiveFilters,
  setSelectedPortfolioOnly,
  DatasetID,
  setSurveyTopic,
  vcSurveyTopics,
  VCSurveyTopic,
  IFilters,
} from "../../data/filters";
import { permissionsSelector } from "../../data/session";
import { RootState } from "../../store";
import { IActionBody } from "../../utils/redux";
import { Button } from "../Button";
import { Container } from "../Grid";
import { Icon } from "../Icon";
import { Select } from "../Select";
import "./Filters.scss";

interface ComponentProps {
  dataset: DatasetID;
  filtersRef: RefObject<HTMLDivElement>;
}

interface MapStateProps extends IPlotParameters {
  working: boolean;
  canViewSelectedPortfolio: boolean;
}

const mapStateToProps = (state: RootState) => {
  const { payload, working } = filterSelector(state);
  const { canViewSelectedPortfolio } = permissionsSelector(state);

  return {
    filtersData: payload.filtersData,
    selectedFilters: payload.selectedFilters,
    selectedPortfolioOnly: payload.selectedPortfolioOnly,
    vcSurveyTopic: payload.vcSurveyTopic,
    working,
    canViewSelectedPortfolio,
  };
};

interface MapDispatchProps {
  clearFilters: () => void;
  setActiveFilters: (
    params: IActionBody<IPlotParameters["selectedFilters"]>
  ) => void;
  setSelectedPortfolioOnly: (
    params: IActionBody<IPlotParameters["selectedPortfolioOnly"]>
  ) => void;
  setSurveyTopic: (
    params: IActionBody<IPlotParameters["vcSurveyTopic"]>
  ) => void;
}

const actionCreators = {
  clearFilters,
  setActiveFilters,
  setSelectedPortfolioOnly,
  setSurveyTopic,
};

interface ComponentState {
  clearFilters: boolean;
}

type Props = ComponentProps & MapDispatchProps & MapStateProps;
type State = ComponentState;

class FiltersComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { clearFilters: false };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { dataset } = this.props;

    if (dataset !== prevProps.dataset) {
      this.handleClearFilters();
    }
  }

  handleSelectFilter = (value: string, key: string) => {
    const { selectedFilters } = this.props;
    // Add a new dimension
    if (!selectedFilters.hasOwnProperty(key)) {
      const filtersToAdd = Object.assign({}, selectedFilters, {
        [key]: [value],
      });
      this.props.setActiveFilters({ payload: filtersToAdd });
    }
    // Add an admitted value to a dimension existing in selectedFilters
    if (
      selectedFilters.hasOwnProperty(key) &&
      !selectedFilters[key].includes(value)
    ) {
      const filtersToAdd = Object.assign({}, selectedFilters, {
        [key]: [...selectedFilters[key], value],
      });

      this.props.setActiveFilters({ payload: { ...filtersToAdd } });
    }
    // Remove an admitted value from an existing dimension
    if (
      selectedFilters.hasOwnProperty(key) &&
      selectedFilters[key].includes(value)
    ) {
      const filteredValues = selectedFilters[key].filter(
        (val) => val !== value
      );

      if (filteredValues.length === 0) {
        const { [key]: _, ...filtersToSet } = selectedFilters;
        this.props.setActiveFilters({ payload: { ...filtersToSet } });
      } else {
        const filtersToSet = Object.assign({}, selectedFilters, {
          [key]: filteredValues,
        });
        this.props.setActiveFilters({ payload: { ...filtersToSet } });
      }
    }
  };

  handlePortfolioCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setSelectedPortfolioOnly({ payload: event.target.checked });
  };

  handleClearFilters = () => {
    this.setState({ clearFilters: true });
    this.props.clearFilters();
    setTimeout(() => {
      this.setState({ clearFilters: false });
    }, 200);
  };

  handleSelectAllFilter = (values: string[], key: string) => {
    const { selectedFilters } = this.props;
    const filtersToAdd = {
      ...selectedFilters,
      [key]: values,
    };
    this.props.setActiveFilters({ payload: filtersToAdd });
  };

  handleUnselectAllFilter = (key: string) => {
    const { selectedFilters } = this.props;
    if (selectedFilters.hasOwnProperty(key)) {
      const { [key]: _, ...filtersToSet } = selectedFilters;
      this.props.setActiveFilters({ payload: { ...filtersToSet } });
    }
  };

  handleSelectTopic = (value: string) => {
    this.props.setSurveyTopic({ payload: value as VCSurveyTopic });
  };

  renderFilters = (
    filters: IFilters,
    selectedFilters: IPlotParameters["selectedFilters"]
  ) =>
    Object.entries<IFilter>(filters).map(([key, filter], i) => (
      <div className="Filters__content--single" key={i}>
        <label className=" Filters__content--label" htmlFor={`filter${i}`}>
          {filter.label}
        </label>
        <Select
          id={i.toString()}
          placeholder={filter.label}
          options={filter.values}
          onSelect={(value: string) => this.handleSelectFilter(value, key)}
          selected={selectedFilters[key] || []}
          clearSearchInput={this.state.clearFilters}
          onSelectAllFilter={(values) =>
            this.handleSelectAllFilter(values, key)
          }
          onUnselectAllFilter={() => this.handleUnselectAllFilter(key)}
        />
      </div>
    ));

  render() {
    const {
      filtersData,
      dataset,
      selectedFilters,
      filtersRef,
      canViewSelectedPortfolio,
      vcSurveyTopic,
    } = this.props;
    const filters = filtersData[dataset];

    return filters ? (
      <div className="Filters" ref={filtersRef}>
        <Container>
          <div className="Filters__title">
            <Icon icon="filters" />
            <h3>Filters</h3>
            <span className="Filters__divider" />
            <Button
              onClick={this.handleClearFilters}
              type="button"
              outline
              className="Filters__clear"
            >
              Clear filters
            </Button>
            {canViewSelectedPortfolio ? (
              <div className="Filters__portfolioCBox">
                <label
                  className="Filters__portfolioCBox--label"
                  htmlFor="SelectedPortfolio"
                >
                  Show Selected Portfolio Only
                </label>
                <input
                  id="SelectedPortfolio"
                  type="checkbox"
                  name="SelectedPortfolio"
                  value="Yes"
                  checked={this.props.selectedPortfolioOnly}
                  onChange={this.handlePortfolioCheckbox}
                />
              </div>
            ) : null}
          </div>
          <div className="Filters__content">
            {dataset === "VcSurveyData" ? (
              <div className="Filters__content--single">
                <label
                  className=" Filters__content--label"
                  htmlFor="VCSurveyTopic"
                >
                  Survey Topic
                </label>
                <Select
                  id="VCSurveyTopic"
                  placeholder={"Topic"}
                  options={vcSurveyTopics}
                  onSelect={this.handleSelectTopic}
                  selected={[vcSurveyTopic]}
                  clearSearchInput={this.state.clearFilters}
                  withSelectAllOption={false}
                />
              </div>
            ) : null}
            {this.renderFilters(filters, selectedFilters)}
          </div>
          {dataset === "VcSurveyData" ? (
            <div className="Filters__questions">
              {getVcSurveyQuestions(vcSurveyTopic)[0] ? (
                <p>
                  Current {vcSurveyTopic} refers to the question:{" "}
                  <em>{getVcSurveyQuestions(vcSurveyTopic)[0]}</em>
                </p>
              ) : null}
              <p>
                Future {vcSurveyTopic} refers to the question:{" "}
                <em>{getVcSurveyQuestions(vcSurveyTopic)[1]}</em>
              </p>
            </div>
          ) : null}
        </Container>
      </div>
    ) : null;
  }
}

const getVcSurveyQuestions = (
  surveyTopic: VCSurveyTopic
): [string | undefined, string] => {
  switch (surveyTopic) {
    case "Fundraising Environment":
      return [
        "How would you rate the current fundraising environment for VC funds?",
        "Over the next 12 months, how do you expect the fundraising environment to develop?",
      ];
    case "State of Business":
      return [
        "How would you assess the current state of your business?",
        "How do you expect the state of your business to change over the next 12 months?",
      ];
    case "Portfolio Development":
      return [
        "How did your venture portfolio companies develop over the last 12 months?",
        "Over the next 12 months, how do you expect your overall venture portfolio to develop?",
      ];
    case "Easiness to Find Co-Investors":
      return [
        "How easy/difficult is it currently to find co-investors to syndicate?",
        "Over the next 12 months, how do you expect finding co-investors to syndicate to become?",
      ];
    case "Investment Proposals":
      return [
        undefined,
        "How do you expect the number of qualified venture investment proposals to your firm to develop over the next 12 months?",
      ];
    case "New Investments":
      return [
        undefined,
        "How do you expect the number of your new venture investments to develop over the next 12 months?",
      ];
    case "Exit Opportunities":
      return [
        undefined,
        "Over the next 12 months, how do you expect the exit opportunities of your venture portfolio companies to develop?",
      ];
  }
};

export const Filters = connect(
  mapStateToProps,
  actionCreators
)(FiltersComponent);
