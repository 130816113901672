type NumberFormatter = (n: number) => string;

const eurFormat = Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "EUR",
});

export const formatEUR: NumberFormatter = (n) => {
  let scaled: number;
  let suffix: string;
  if (n > 1e9) {
    scaled = n / 1000000000;
    suffix = "b";
  } else if (n > 1e6) {
    scaled = n / 1000000;
    suffix = "m";
  } else if (n > 1e3) {
    scaled = n / 1000;
    suffix = "k";
  } else {
    scaled = n;
    suffix = "";
  }

  return eurFormat.format(scaled) + suffix;
};

const multipleFormat = Intl.NumberFormat(undefined, {
  style: "decimal",
  maximumFractionDigits: 2,
});

export const formatMultiple: NumberFormatter = (n) =>
  multipleFormat.format(n) + "x";

const percentFormatWithFractionDigits = (fractionDigits: number) =>
  Intl.NumberFormat(undefined, {
    style: "percent",
    maximumFractionDigits: fractionDigits,
  });

export const formatPercent: NumberFormatter = (n) =>
  percentFormatWithFractionDigits(2).format(n);

export const formatPercentWithZeroFractionDigits: NumberFormatter = (n) =>
  percentFormatWithFractionDigits(0).format(n);
