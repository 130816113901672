import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "../../components/Button";
import { Col, Container, Row } from "../../components/Grid";
import { Icon } from "../../components/Icon";
import {
  ILoginModalState,
  sessionSelector,
  toggleLoginModal,
} from "../../data/session";
import AboutImage from "../../images/TheBench_about.png";
import AboutImage2x from "../../images/TheBench_about_x2.png";
import TheBenchAnim from "../../animations/TheBench_1700x1200_30s_LQ.mp4";
import BannerImage2x from "../../images/TheBench_banner_x2.png";
import { RootState } from "../../store";
import { IActionBody } from "../../utils/redux";
import "./Home.scss";

interface ComponentProps {
  authenticated: boolean;
  working: boolean;
  toggleLoginModal: (props: IActionBody<ILoginModalState>) => void;
  isLoginModalOpen: boolean;
}
type Props = ComponentProps & RouteComponentProps;

class HomeViewComponent extends Component<Props> {
  render() {
    const { authenticated, isLoginModalOpen } = this.props;
    return (
      <div className="Home">
        <div className="Home__header">
          <Container>
            <Row>
              <Col
                className="Home__header-picture-container"
                size={{ sm: 12, md: 6, lg: 6, xl: 6 }}
                grow={1}
                justify="center"
                align="center"
                flex={true}
              >
                <h1 className="--text-color-primary text--line-double Home__header-lead hidden-md-up">
                  The bench.
                </h1>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={TheBenchAnim}
                  poster={BannerImage2x}
                  className="Home__header-picture"
                />
              </Col>
              <Col
                className="Home__header-info-container"
                size={{ sm: 12, md: 6, lg: 6, xl: 6 }}
              >
                <h1 className="--text-color-primary text--line-double Home__header-lead hidden-sm-down">
                  The bench.
                </h1>
                <span className="text--line-double">
                  Unrivaled access to 20+ years of data and insights into the
                  EIF's venture capital portfolio.
                </span>
                <Row className="Home__header-buttons">
                  {!authenticated ? (
                    <>
                      <Button
                        size="lg"
                        onClick={() =>
                          this.props.toggleLoginModal({
                            payload: { isLoginModalOpen: !isLoginModalOpen },
                          })
                        }
                      >
                        Login
                      </Button>
                      <a
                        href="mailto:thebench@eif.org?subject=New%20Access%20Request"
                        className="Button Button--outline"
                      >
                        <Icon icon={"pen"} />
                        Request Invite
                      </a>
                    </>
                  ) : (
                    <Button
                      success
                      size="lg"
                      href={process.env.REACT_APP_REDIRECT_URI_AFTER_LOGIN}
                    >
                      Explore Data
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="Home__funds">
          <Container>
            <Col
              align="center"
              flex={true}
              grow={1}
              className="Home__funds-heading"
            >
              <h1 className="Home__funds-heading-title">Who is it for ?</h1>
              <h4>Members of the European venture capital ecosystem</h4>
            </Col>
            <Row>
              <Col size={{ sm: 12, md: 6, lg: 4, xl: 4 }}>
                <Row align="center" className="Home__funds-item-header">
                  <Icon icon="circle" />
                  <h4>Investors</h4>
                </Row>
                <p>
                  Compare fund performance data across stages, sectors and
                  vintages, understand who the largest investors are in Europe
                  and how fund economics have developed over time.
                </p>
              </Col>
              <Col size={{ sm: 12, md: 6, lg: 4, xl: 4 }}>
                <Row align="center" className="Home__funds-item-header">
                  <Icon icon="circle" />
                  <h4>Founders &amp; Start-ups</h4>
                </Row>
                <p>
                  Navigate the European investor landscape for start-ups,
                  discover major funding trends by stage, sector and country,
                  and explore the performance of companies similar to yours.
                </p>
              </Col>
              <Col size={{ sm: 12, md: 6, lg: 4, xl: 4 }}>
                <Row align="center" className="Home__funds-item-header">
                  <Icon icon="circle" />
                  <h4>Other Stakeholders</h4>
                </Row>
                <p>
                  Delve into a unique cluster of historical data on European
                  venture capital, including formerly unpublished details from
                  the EIF's own portfolio across different stages, vintages and
                  sectors.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="Home__about">
          <Container>
            <Row>
              <Col size={12}>
                <h1 className="Home__about-title">About us</h1>
              </Col>
            </Row>
            <Row>
              <Col
                size={{ sm: 12, md: 6, lg: 6, xl: 6 }}
                grow={1}
                justify="center"
                align="center"
                flex={true}
              >
                <img
                  src={AboutImage}
                  className="responsive"
                  srcSet={`${AboutImage2x} 2x`}
                  alt=""
                />
              </Col>
              <Col size={{ sm: 12, md: 6, lg: 6, xl: 6 }}>
                <p className="margin-top--xl text--line-double">
                  The bench is an initiative of the{" "}
                  <a href="https://www.eif.org">European Investment Fund</a>{" "}
                  (EIF), Europe’s primary provider of risk financing for small
                  businesses. With 8000+ portfolio companies and investments in
                  700+ funds to date, we are one of the largest market players
                  in European venture capital and private equity. Since the
                  EIF's establishment in 1994, we have been acting as a market
                  developer and market catalyst in line with our mission to
                  build sustainable ecosystems, whilst also delivering returns
                  to our investors. This platform lifts the lid on data related
                  to our activities over the years.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = { toggleLoginModal };

const mapStateToProps = (state: RootState) => {
  const { authenticated, working, isLoginModalOpen } = sessionSelector(state);
  return {
    authenticated,
    working,
    isLoginModalOpen,
  };
};

export const HomeView = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeViewComponent);
