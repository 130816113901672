import gql from "graphql-tag";

import { formatMultiple } from "../../../utils/formatNumbers";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import {
  PRIMARY_INDIGO,
  PRIMARY_PINK,
  PRIMARY_YELLOW,
} from "../../../utils/colors";

export const fundDPIBySector: PlotSpec = {
  query: gql`
    query fundDPIBySector(
      $filters: fundsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      funds(
        dimensions: ["sectorGroup"]
        filters: $filters
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        sectorGroup
        dpiQ1: dpiQuantile(q: 0.75)
        dpiQ2: dpiQuantile(q: 0.50)
        dpiQ3: dpiQuantile(q: 0.25)
        numFunds
        censoringInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "bar",
        x: data.funds.sectorGroup,
        y: data.funds.dpiQ1,
        hovertext: data.funds.dpiQ1.map(formatMultiple),
        hoverinfo: "text+name",
        name: "DPI Q1",
        marker: {
          color: PRIMARY_PINK,
        },
      },
      {
        type: "bar",
        x: data.funds.sectorGroup,
        y: data.funds.dpiQ2,
        hovertext: data.funds.dpiQ2.map(formatMultiple),
        hoverinfo: "text+name",
        name: "DPI Q2",
        marker: {
          color: PRIMARY_INDIGO,
        },
      },
      {
        type: "bar",
        x: data.funds.sectorGroup,
        y: data.funds.dpiQ3,
        hovertext: data.funds.dpiQ3.map(formatMultiple),
        hoverinfo: "text+name",
        name: "DPI Q3",
        marker: {
          color: PRIMARY_YELLOW,
        },
      },
    ],
    layout: {
      ...getDefaultLayout(),
      margin: { t: 20, r: 20, b: 80, l: 30 },
    },
  }),
  censoringChecker: (data) => data.funds.censoringInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Sector", "DPI Q1", "DPI Q2", "DPI Q3", "Number of Funds"],
    data: [
      data.funds.sectorGroup,
      data.funds.dpiQ1.map(formatMultiple),
      data.funds.dpiQ2.map(formatMultiple),
      data.funds.dpiQ3.map(formatMultiple),
      data.funds.numFunds,
    ],
  }),
};
