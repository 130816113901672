import gql from "graphql-tag";

import { formatEUR, formatMultiple } from "../../../utils/formatNumbers";
import {
  PRIMARY_INDIGO,
  PRIMARY_INDIGO_30,
  PRIMARY_PINK,
} from "../../../utils/colors";
import { getDefaultLayout } from "../../../utils/defaultLayout";

interface IFundsVintage {
  funds: {
    vintageYear: string[];
    eifCommitment: number[];
    catalyticEffect: number[];
    numFunds: number[];
  };
}

// Milliseconds in a year
const MS_PER_YEAR = 31536000000;

export const fundCommitByVintage: PlotSpec = {
  query: gql`
    query fundsVintage($filters: fundsFilter, $selectedPortfolioOnly: Boolean) {
      funds(
        dimensions: ["vintageYear"]
        filters: $filters
        sorted: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        vintageYear
        eifCommitment
        catalyticEffect
        numFunds
      }
    }
  `,
  plotGenerator: ({ funds }: IFundsVintage) => {
    return {
      data: [
        {
          type: "bar",
          x: funds.vintageYear,
          y: funds.numFunds,
          name: "Nr. Commitments",
          hoverinfo: "name+y",
          marker: {
            color: PRIMARY_INDIGO,
          },
          width: MS_PER_YEAR * 0.4,
          offset: -MS_PER_YEAR * 0.4,
        },
        {
          type: "bar",
          x: funds.vintageYear,
          y: funds.eifCommitment,
          yaxis: "y2",
          hovertext: funds.eifCommitment.map(formatEUR),
          hoverinfo: "name+text",
          marker: {
            color: PRIMARY_INDIGO_30,
          },
          name: "EIF Commitment",
          width: MS_PER_YEAR * 0.4,
          offset: 0,
        },
        {
          type: "scatter",
          mode: "lines+markers",
          x: funds.vintageYear,
          y: funds.catalyticEffect,
          yaxis: "y3",
          hovertext: funds.catalyticEffect.map(formatMultiple),
          hoverinfo: "name+text",
          base: 0,
          marker: { opacity: 0.5, color: PRIMARY_PINK },
          name: "Catalytic Effect",
        },
      ],
      layout: {
        ...getDefaultLayout("year"),
        // Reduced font size otherwise tick labels touch
        font: {
          family: "'Canela Text', sans-serif",
          size: 12,
        },
        yaxis2: {
          overlaying: "y",
          side: "right",
          fixedrange: true,
        },
        yaxis3: {
          visible: false,
          overlaying: "y",
          fixedrange: true,
        },
      },
    };
  },
  censoringChecker: () => false,
  dataTable: ({ funds }: IFundsVintage) => {
    return {
      headers: [
        "Year",
        "Nr. Commitments",
        "EIF Commitment",
        "Catalytic Effect",
      ],
      data: [
        funds.vintageYear,
        funds.numFunds,
        funds.eifCommitment.map(formatEUR),
        funds.catalyticEffect.map(formatMultiple),
      ],
    };
  },
};
