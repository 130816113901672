import ExplorerView from "./Explorer";
import {
  FundEconomy,
  FundPerformance,
  FundPortfolio,
  FundRaising,
  VcSurvey,
  Glossary,
} from "./components";
import { PlotTabConnectedAndScrolling } from "./components/types";
import { DatasetID } from "../../data/filters";

export const route = {
  component: ExplorerView,
  label: "Explorer",
  name: "Explorer",
  path: "/view/:tabGroup/:tab?",
  exact: true,
};

// The tabGroup is extracted from the URL, the part after /view/
// So this needs to be consistent with the paths in fundsRoutes
export const tabGroupToDatasetMap: { [T: string]: DatasetID } = {
  funds: "FundsData",
  investments: "InvestmentData",
  vcSurveys: "VcSurveyData",
};

export const fundsRoutes: Array<INavBarRoute<PlotTabConnectedAndScrolling>> = [
  {
    name: "fundraising",
    label: "Fundraising",
    path: "/view/funds/fundraising",
    component: FundRaising,
    subMenu: {
      fundsVintage: "EIF Commitment by Vintage",
      fundsCountry: "EIF Commitment by Team Location",
      investorGroupsCommitment: "Total Commitment by Investor Group",
    },
  },
  {
    name: "fund-performance",
    label: "Fund Performance",
    path: "/view/funds/fund-performance",
    component: FundPerformance,
    subMenu: {
      fundIRRByVintage: "IRR by Vintage",
      fundIRRBySector: "IRR by Sector",
      fundTVPIByVintage: "TVPI by Vintage",
      fundTVPIBySector: "TVPI by Sector",
      fundsDPIByVintage: "DPI by Vintage",
      fundDPIBySector: "DPI by Sector",
    },
  },
  {
    name: "fund-economics",
    label: "Fund Economics",
    path: "/view/funds/fund-economics",
    component: FundEconomy,
    subMenu: {
      fundIPMgmtFeeByVintage: "IP Fees by Vintage",
      fundPostIPMgmtFeeByVintage: "Post IP Fees by Vintage",
      fundCarryByVintage: "Carry by Vintage",
      fundHurdleByVintage: "Hurdle by Vintage",
    },
  },
  {
    name: "fund-portfolio",
    label: "Fund Portfolio",
    path: "/view/investments/fund-portfolio",
    component: FundPortfolio,
    subMenu: {
      investmentByCompanyLocation: "Investment by Company Location",
      investmentMocByInvestmentYear: "MoC by Investment Year",
      investmentMocBySector: "MoC by Sector",
    },
  },
  {
    name: "vc-survey",
    label: "EIF VC Surveys",
    path: "/view/vcSurveys",
    component: VcSurvey,
    subMenu: {
      publications: "Publications",
      current: "Current Situation",
      future: "Future Expectation",
      byCountryGroup: "Result by Country Group",
      bySector: "Result by Sector",
      byStageFocus: "Result by Stage Focus",
    },
  },
  {
    name: "spacer",
    label: "",
    path: "#",
  },
  {
    name: "glossary",
    label: "Q&A | Glossary",
    path: "/view/glossary",
    component: Glossary,
    icon: "glossary",
  },
];
