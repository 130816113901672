import { Selector } from "reselect";
import { RootState } from "../../store";
import { FiltersState } from "./store";
import { IChosenPlotParameters } from "./types";

const chosenFilters: Selector<RootState, IChosenPlotParameters> = (
  state: RootState
) => {
  const {
    selectedFilters,
    selectedPortfolioOnly,
    vcSurveyTopic,
  } = state.filters.payload;
  return {
    chosenFilters: { selectedFilters, selectedPortfolioOnly },
    vcSurveyTopic,
  };
};

export const selectors = chosenFilters;

export const filterSelector: Selector<RootState, FiltersState> = (
  state: RootState
) => state.filters;
