import React, { FunctionComponent } from "react";
import { useOutsideClick } from "../../../../../utils/hooks";

import { Button } from "../../../../Button";
import { Icon } from "../../../../Icon";

import "./HeaderAccount.scss";

interface ComponentProps {
  signOut: () => void;
  username?: string;
}

export const HeaderAccount: FunctionComponent<ComponentProps> = ({
  username,
  signOut,
}) => {
  const { nodeRef, isOpen, openHandler } = useOutsideClick();

  return (
    <div onClick={() => openHandler(!isOpen)} className="HeaderAccount">
      <div className="HeaderAccount__user">
        <Icon icon="user" />
        <span className="hidden-xs-down">{username}</span>
      </div>
      {isOpen && (
        <div ref={nodeRef} className="HeaderAccount__dropdown">
          <div className="HeaderAccount__dropdown-arrow" />
          <div className="HeaderAccount__dropdown-list">
            <li>
              <Button flat block onClick={signOut}>
                Logout
              </Button>
            </li>
          </div>
        </div>
      )}
    </div>
  );
};
