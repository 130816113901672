import React, { FunctionComponent } from "react";

import { Button } from "../Button";

import "./Banner.scss";

interface BannerProps {
  showCookiePolicy: () => void;
  cookieBannerHidden: boolean;
  hideCookieBanner: () => void;
}

export const Banner: FunctionComponent<BannerProps> = ({
  showCookiePolicy,
  cookieBannerHidden,
  hideCookieBanner,
}) => {
  if (!cookieBannerHidden) {
    return (
      <div className="Banner">
        <p>
          This website uses cookies exclusively to implement required
          functionality.{" "}
          <Button onClick={showCookiePolicy}>Show Details</Button> or{" "}
          <Button onClick={hideCookieBanner} className="Banner__button">
            Hide
          </Button>
        </p>
      </div>
    );
  } else {
    return null;
  }
};
