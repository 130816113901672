import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";

import { RootState } from "./store";
import {
  ILoginModalState,
  initSession,
  ISession,
  sessionSelector,
  sessionDestroy,
  toggleLoginModal,
} from "./data/session";
import {
  openTermsOfUse,
  acceptTermsOfUse,
  requireTermsOfUseAcceptance,
  openPrivacyNotice,
  closePrivacyNotice,
  openCookiePolicy,
  closeCookiePolicy,
  hideCookieBanner,
} from "./data/disclaimers";
import { getFilters } from "./data/filters";

import "./utils/amplifyConfig";

import { PageHeader, PageFooter } from "./components/Page";
import { Banner } from "./components/Banner";
import Spinner from "./components/Spinner";

import { ProtectedRoutes, Routes } from "./views/Routes";

import "./scss/index.scss";
import { ModalWithRouter } from "./components/Modal";
import { Disclaimer } from "./components/Disclaimer";
import { ApolloProvider } from "react-apollo";
import clientGraphQL from "./utils/clientGraphQL";

const renderRoutes = (routes: TRoutes) => {
  return routes.map((route) => <Route key={route.name} {...route} />);
};

const renderProtectedRoutes = (isLoggedIn: boolean | undefined) => {
  return !isLoggedIn ? renderRoutes(Routes) : renderRoutes(ProtectedRoutes);
};

interface ComponentProps {}

interface ComponentState {
  shouldRedirect: boolean;
}

interface MapStateProps extends ILoginModalState {
  authenticated: boolean;
  username: ISession["username"];
  working: boolean;
  termsOfUseModalOpen: boolean;
  privacyNoticeModalOpen: boolean;
  cookieBannerHidden: boolean;
  cookiePolicyModalOpen: boolean;
}

const mapStateToProps = (state: RootState) => {
  const {
    authenticated,
    working,
    username,
    isLoginModalOpen,
  } = sessionSelector(state);
  const {
    termsOfUseModalOpen,
    privacyNoticeModalOpen,
    cookieBannerHidden,
    cookiePolicyModalOpen,
  } = state.disclaimers;
  return {
    username,
    authenticated,
    working,
    isLoginModalOpen,
    termsOfUseModalOpen,
    privacyNoticeModalOpen,
    cookieBannerHidden,
    cookiePolicyModalOpen,
  };
};

interface MapDispatchProps {
  getFilters: () => void;
  sessionDestroy: () => void;
  initSession: () => Promise<any>;
  toggleLoginModal: () => void;
  openTermsOfUse: () => void;
  acceptTermsOfUse: () => void;
  requireTermsOfUseAcceptance: () => void;
  openPrivacyNotice: () => void;
  closePrivacyNotice: () => void;
  openCookiePolicy: () => void;
  closeCookiePolicy: () => void;
  hideCookieBanner: () => void;
}

const actionCreators = {
  getFilters,
  sessionDestroy,
  initSession,
  toggleLoginModal,
  openTermsOfUse,
  acceptTermsOfUse,
  requireTermsOfUseAcceptance,
  openPrivacyNotice,
  closePrivacyNotice,
  openCookiePolicy,
  closeCookiePolicy,
  hideCookieBanner,
};

type Props = ComponentProps & MapStateProps & MapDispatchProps;
type State = ComponentState;

class AppComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldRedirect: false,
    };
    this.init();
  }

  componentDidUpdate() {
    if (this.props.authenticated) {
      this.props.getFilters();
    }
  }

  async init() {
    const {
      authenticated,
      initSession,
      working,
      requireTermsOfUseAcceptance,
    } = this.props;
    if (!authenticated && !working) {
      try {
        await initSession();
        requireTermsOfUseAcceptance();
      } catch (e) {
        this.setState({ shouldRedirect: true });
      }
    }
  }

  render() {
    const {
      working,
      authenticated,
      username,
      sessionDestroy,
      isLoginModalOpen,
      toggleLoginModal,
      cookieBannerHidden,
    } = this.props;
    const { shouldRedirect } = this.state;
    return (
      <div className="App">
        <ApolloProvider client={clientGraphQL}>
          <BrowserRouter>
            <>
              <PageHeader
                authenticated={authenticated}
                username={username}
                sessionDestroy={sessionDestroy}
                isLoginModalOpen={isLoginModalOpen}
                toggleModal={toggleLoginModal}
              />
              <Spinner large centered show={working}>
                <Banner
                  cookieBannerHidden={cookieBannerHidden}
                  showCookiePolicy={() => this.props.openCookiePolicy()}
                  hideCookieBanner={() => this.props.hideCookieBanner()}
                />
                {this.props.termsOfUseModalOpen && (
                  <ModalWithRouter
                    toggleModal={() => this.props.acceptTermsOfUse()}
                  >
                    <Disclaimer
                      content="TermsOfUse"
                      onSubmit={() => this.props.acceptTermsOfUse()}
                    />
                  </ModalWithRouter>
                )}
                {this.props.privacyNoticeModalOpen && (
                  <ModalWithRouter
                    toggleModal={() => this.props.closePrivacyNotice()}
                  >
                    <Disclaimer
                      content="PrivacyNotice"
                      onSubmit={() => this.props.closePrivacyNotice()}
                    />
                  </ModalWithRouter>
                )}
                {this.props.cookiePolicyModalOpen && (
                  <ModalWithRouter
                    toggleModal={() => this.props.closeCookiePolicy()}
                  >
                    <Disclaimer
                      content="CookiePolicy"
                      onSubmit={() => this.props.closeCookiePolicy()}
                    />
                  </ModalWithRouter>
                )}
                <Switch>
                  {renderProtectedRoutes(authenticated)}
                  {shouldRedirect && <Redirect to="/" />}
                </Switch>
                <PageFooter
                  showTermsOfUse={() => this.props.openTermsOfUse()}
                  showPrivacyNotice={() => this.props.openPrivacyNotice()}
                  showCookiePolicy={() => this.props.openCookiePolicy()}
                />
              </Spinner>
            </>
          </BrowserRouter>
        </ApolloProvider>
      </div>
    );
  }
}

const App = connect(mapStateToProps, actionCreators)(AppComponent);
export default App;
