import gql from "graphql-tag";

import { formatMultiple } from "../../../utils/formatNumbers";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import {
  PRIMARY_INDIGO,
  PRIMARY_PINK,
  PRIMARY_YELLOW,
} from "../../../utils/colors";

export const fundTVPIBySector: PlotSpec = {
  query: gql`
    query fundTVPIBySector(
      $filters: fundsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      funds(
        dimensions: ["sectorGroup"]
        filters: $filters
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        sectorGroup
        tvpiQ1: tvpiQuantile(q: 0.75)
        tvpiQ2: tvpiQuantile(q: 0.50)
        tvpiQ3: tvpiQuantile(q: 0.25)
        numFunds
        censoringInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "bar",
        x: data.funds.sectorGroup,
        y: data.funds.tvpiQ1,
        hovertext: data.funds.tvpiQ1.map(formatMultiple),
        hoverinfo: "x+text+name",
        name: "TVPI Q1",
        marker: {
          color: PRIMARY_PINK,
        },
      },
      {
        type: "bar",
        x: data.funds.sectorGroup,
        y: data.funds.tvpiQ2,
        text: data.funds.tvpiQ2.map(formatMultiple),
        hoverinfo: "x+text+name",
        name: "TVPI Q2",
        marker: {
          color: PRIMARY_INDIGO,
        },
      },
      {
        type: "bar",
        x: data.funds.sectorGroup,
        y: data.funds.tvpiQ3,
        hovertext: data.funds.tvpiQ3.map(formatMultiple),
        hoverinfo: "x+text+name",
        name: "TVPI Q3",
        marker: {
          color: PRIMARY_YELLOW,
        },
      },
    ],
    layout: {
      ...getDefaultLayout(),
      margin: { t: 20, r: 20, b: 80, l: 30 },
    },
  }),
  censoringChecker: (data) => data.funds.censoringInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Sector", "TVPI Q1", "TVPI Q2", "TVPI Q3", "Number of Funds"],
    data: [
      data.funds.sectorGroup,
      data.funds.tvpiQ1.map(formatMultiple),
      data.funds.tvpiQ2.map(formatMultiple),
      data.funds.tvpiQ3.map(formatMultiple),
      data.funds.numFunds,
    ],
  }),
};
