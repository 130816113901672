import gql from "graphql-tag";

import { formatPercentWithZeroFractionDigits } from "../../../utils/formatNumbers";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import {
  PRIMARY_INDIGO,
  PRIMARY_PINK,
  PRIMARY_YELLOW,
} from "../../../utils/colors";
import { VCSurveyTopic } from "../../filters";
import { getGQLFields } from ".";

const vcSurveyByYear = (gqlField: string, tableHeader: string): PlotSpec => {
  return {
    query: gql`
      query vcSurveyFutureFundraisingsByYear($filters: vcSurveyFilter) {
        vcSurvey(dimensions: ["surveyYear"], filters: $filters, sorted: true, removeCensored: true) {
          ${gqlField} {
            labels
            responses
          }
          numRespondents
          surveyYear
          censoringInfo {
            hasCensoredData
          }
        }
      }`,
    plotGenerator: (data) => {
      const stData = structuredData(data.vcSurvey, gqlField);
      const years = data.vcSurvey.surveyYear.map(
        (x: number) => "Survey Year " + x
      );

      return {
        data: [
          {
            type: "bar",
            x: years,
            y: stData.worse.data,
            name: stData.worse.label,
            hovertext: stData.worse.dataFormatted,
            hoverinfo: "x+text+name",
            marker: {
              color: PRIMARY_PINK,
            },
          },
          {
            type: "bar",
            x: years,
            y: stData.same.data,
            name: stData.same.label,
            hovertext: stData.same.dataFormatted,
            hoverinfo: "x+text+name",
            marker: {
              color: PRIMARY_INDIGO,
            },
          },
          {
            type: "bar",
            x: years,
            y: stData.better.data,
            name: stData.better.label,
            hovertext: stData.better.dataFormatted,
            hoverinfo: "x+text+name",
            marker: {
              color: PRIMARY_YELLOW,
            },
          },
        ],
        layout: {
          ...getDefaultLayout(),
          margin: { b: 100, l: 50, r: 20, t: 20 },
          barmode: "stack",
          yaxis: {
            title: "Percentage of Respondents",
            titlefont: {
              size: 16,
              color: "rgb(107, 107, 107)",
            },
          },
          legend: {
            orientation: "h",
            x: 0,
            y: 1.3,
          },
        },
      };
    },
    censoringChecker: (data) => data.vcSurvey.censoringInfo.hasCensoredData,
    dataTable: (data) => {
      const stData = structuredData(data.vcSurvey, gqlField);
      const sortOrder = ["better", "same", "worse"];

      return {
        headers: [tableHeader, "2018", "2019"],
        data: [
          sortOrder.map((lbl) => stData[lbl].label),
          sortOrder.map((lbl) =>
            stData[lbl].dataFormatted.filter(
              (v: number, idx: number) =>
                data.vcSurvey.surveyYear[idx] === "2018"
            )
          ),
          sortOrder.map((lbl) =>
            stData[lbl].dataFormatted.filter(
              (v: number, idx: number) =>
                data.vcSurvey.surveyYear[idx] === "2019"
            )
          ),
        ],
      };
    },
  };
};

/* Zip label with corresponding responses, and compute share.
  
  NB. When `sorted` is set in the query, the server will
  also sort survey question response values in an order 
  matching bad / worse, average / same, good / better
*/
export const structuredData = (data: any, gqlField: string) => {
  const labels = data[gqlField].labels as string[];
  const responses = data[gqlField].responses as number[][];

  const zipped = labels.map((label, idx) => {
    const shareOfRespondents = responses[idx].map(
      (val: number, idx: number) => val / data.numRespondents[idx]
    );

    return {
      label,
      data: shareOfRespondents,
      dataFormatted: shareOfRespondents.map(
        formatPercentWithZeroFractionDigits
      ),
    };
  });

  // Data is sorted server side
  return {
    worse: zipped[0],
    same: zipped[1],
    better: zipped[2],
  };
};

export const vcSurveyCurrent = (surveyTopic: VCSurveyTopic): PlotSpec => {
  const [gqlField] = getGQLFields(surveyTopic);
  if (!gqlField)
    throw Error("This survey topic does not have a 'current' question");

  return vcSurveyByYear(gqlField, `Current ${surveyTopic}`);
};

export const vcSurveyFuture = (surveyTopic: VCSurveyTopic): PlotSpec => {
  const [, gqlField] = getGQLFields(surveyTopic);
  return vcSurveyByYear(gqlField, `Future ${surveyTopic}`);
};
