import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { Button } from "../../Button";
import { Container } from "../../Grid";
import { Login } from "../../Login";
import { Icon } from "../../Icon";
import { ModalWithRouter } from "../../Modal";

import { HeaderAccount } from "./components/HeaderAccount";
import "./PageHeader.scss";
import Logo from "../../../images/logo.svg";
import { ILoginModalState } from "../../../data/session";

interface ComponentProps extends RouteComponentProps, ILoginModalState {
  authenticated: boolean;
  username?: string;
  sessionDestroy: () => void;
  toggleModal: () => void;
}

const HeaderComponent = (props: ComponentProps) => {
  const toggleModal = (redirect = false) => {
    const { toggleModal, history } = props;
    toggleModal();
    if (redirect) {
      history.push(`${process.env.REACT_APP_REDIRECT_URI_AFTER_LOGIN}`);
    }
  };

  const handleSignOut = () => {
    props.sessionDestroy();
    props.history.push("/");
  };

  const { authenticated, username, isLoginModalOpen } = props;

  return (
    <>
      {isLoginModalOpen && (
        <ModalWithRouter toggleModal={toggleModal}>
          <Login onSubmit={toggleModal} />
        </ModalWithRouter>
      )}
      <div className="PageHeader">
        <Container>
          <div className="PageHeader__content">
            <Link to="/">
              <img src={Logo} className="PageHeader__logo" alt="" />
            </Link>
            {!authenticated && (
              <div className="PageHeader__content-user">
                <a
                  href="mailto:thebench@eif.org?subject=New%20Access%20Request"
                  className="Button Button--outline"
                >
                  <Icon className="hidden-xs-down" icon={"pen"} />
                  Request Invite
                </a>
                <span className="PagesHeader__divider">&nbsp;</span>
                <Button onClick={() => toggleModal()}>Login</Button>
              </div>
            )}
            {authenticated && (
              <HeaderAccount signOut={handleSignOut} username={username!} />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export const PageHeader = withRouter(HeaderComponent);
