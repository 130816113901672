import gql from "graphql-tag";

import { formatEUR } from "../../../utils/formatNumbers";
import { HEATMAP_COLORS } from "../../../utils/colors";
import { getDefaultLayout } from "../../../utils/defaultLayout";

export const investmentByCompanyLocation: PlotSpec = {
  query: gql`
    query investmentByCompanyLocation(
      $filters: investmentsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      investments(
        dimensions: ["investmentCountry"]
        filters: $filters
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        investmentCountry
        eifInvestment
        numInvestments
        censoringInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "choropleth",
        locationmode: "country names",
        locations: data.investments.investmentCountry,
        z: data.investments.eifInvestment,
        hovertext: data.investments.eifInvestment.map(
          (elt: number, idx: number) =>
            `${formatEUR(elt)}<br>${
              data.investments.numInvestments[idx]
            } Investments`
        ),
        hoverinfo: "text+location",
        name: "Investments (EIF Share)",
        zmin: 2,
        colorbar: {
          x: 0,
          autotick: true,
          thickness: 10,
          tickprefix: "€",
        },
        colorscale: [...HEATMAP_COLORS],
      },
    ],
    layout: {
      ...getDefaultLayout(),
      xaxis: { range: [0, 0], autorange: true },
      yaxis: { range: [0, 0], autorange: true },
      margin: { b: 0, l: 0, r: 0, t: 0 },
      geo: {
        lataxis: { range: [0, 0], showgrid: true, gridwidth: 2 },
        lonaxis: { range: [0, 0], showgrid: true, gridwidth: 2 },
        showframe: false,
        showcoastlines: true,
        projection: {
          type: "hammer",
          scale: 3.935039464601701,
          rotation: { lat: 0, lon: 0, roll: 0 },
        },
        center: { lat: 48.9494062370519, lon: 12.381614668644168 },
        bgcolor: "rgba(255, 255, 255, .7)",
        showocean: true,
        oceancolor: "rgba(32, 145, 238, 0.05)",
      },
    },
  }),
  censoringChecker: (data) => data.investments.censoringInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Country", "Investments (EIF Share)"],
    data: [
      data.investments.investmentCountry,
      data.investments.eifInvestment.map(formatEUR),
    ],
  }),
};
