import React from "react";
import classNames from "classnames";
import "./Spinner.scss";

interface ISpinner {
  show?: boolean;
  small?: boolean;
  large?: boolean;
  centered?: boolean;
  id?: string;
  children?: any;
}

type Props = ISpinner;

const Spinner = ({ id, show, centered, children, small, large }: Props) => {
  if (!show) {
    return children || null;
  }

  const spinnerClassNames = classNames("Spinner", {
    "Spinner--small": small,
    "Spinner--large": large,
    "Spinner--centered": centered,
  });

  const spinner = <div id={id} className={spinnerClassNames} />;

  if (centered) {
    return <div className="Spinner--centered__wrapper">{spinner}</div>;
  }
  return spinner;
};

Spinner.displayName = "Spinner";

Spinner.defaultProps = {
  show: true,
  small: false,
  large: false,
  centered: false,
};

export default Spinner;
