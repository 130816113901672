import * as React from "react";
import Plot from "react-plotly.js";
import { Query, QueryResult } from "react-apollo";
import { ApolloError } from "apollo-boost";
import { plots, PlotID } from "../../data/plots";
import Spinner from "../Spinner";
import { DataTable } from "../DataTable";
import { Col, Row } from "../Grid";
import { Icon } from "../Icon";
import { IChosenPlotParameters } from "../../data/filters";
import Message from "../Message";

interface LowerLevelPlotProps {
  plot?: PlotlyDef;
  loading?: boolean;
  isCensored?: boolean;
  dataTable?: any;
  error?: ApolloError;
}

const LowerLevelPlot = ({
  plot,
  loading = false,
  isCensored = false,
  error,
}: LowerLevelPlotProps) => {
  const plotlyConfig: Partial<Plotly.Config> = {
    displaylogo: false,
    modeBarButtonsToRemove: ["sendDataToCloud"],
    displayModeBar: false,
    responsive: true,
  };

  const plotFrame = plot ? (
    <Plot
      {...plot}
      layout={plot.layout}
      config={plotlyConfig}
      style={{ width: "100%" }}
    />
  ) : null;

  return (
    <>
      {isCensored && (
        <div className="Explorer__graph--hidden_points">
          <Icon icon="warning" />
          To protect confidentiality, some data points were omitted in the below
          chart
        </div>
      )}
      {plotFrame}
    </>
  );
};

const queryResultFacade = (
  plotGenerator: TPlotGenerator,
  censoringChecker: TCensoringChecker = () => false,
  dataTable: TDataTable
) => ({ loading, error, data }: QueryResult) => {
  const tableDataDef = !loading && !error ? dataTable(data) : undefined;
  const tableData = tableDataDef?.data ?? [];
  const tableHeaders = tableDataDef?.headers ?? [];
  return (
    <Spinner centered show={loading} large>
      {!error ? (
        <Row>
          <Col size={tableDataDef ? { sm: 12, md: 12, lg: 8, xl: 8 } : 12}>
            <LowerLevelPlot
              plot={!loading && !error ? plotGenerator(data) : undefined}
              loading={loading}
              error={error}
              isCensored={
                !loading && !error ? censoringChecker(data) : undefined
              }
            />
          </Col>
          {dataTable && (
            <Col size={{ sm: 12, md: 12, lg: 4, xl: 4 }}>
              <DataTable
                data={tableData}
                headers={tableHeaders}
                headerCols={tableDataDef?.headerCols}
              />
            </Col>
          )}
        </Row>
      ) : (
        <Message
          message={error.message}
          header="Error while retrieving plot data"
        />
      )}
    </Spinner>
  );
};

interface BenchmarkingPlotGQLProps {
  plotID: PlotID;
  filters: IChosenPlotParameters["chosenFilters"];
  vcSurveyTopic?: IChosenPlotParameters["vcSurveyTopic"];
}

export const BenchmarkingPlotGQL = ({
  plotID,
  filters,
  vcSurveyTopic,
}: BenchmarkingPlotGQLProps) => {
  const { query, plotGenerator, censoringChecker, dataTable } = plots(
    plotID,
    vcSurveyTopic
  );
  const variables = {
    filters: filters.selectedFilters,
    selectedPortfolioOnly: filters.selectedPortfolioOnly,
  };
  return (
    <Query query={query} variables={variables}>
      {queryResultFacade(plotGenerator, censoringChecker, dataTable)}
    </Query>
  );
};

export default BenchmarkingPlotGQL;
