import React, { FunctionComponent } from "react";
import classNames from "classnames";

export interface IRowProperties {
  justify?: TStyleFlexJustify;
  align?: TStyleFlexAlign;
  grow?: IStyleFlexGrow;
  direction?: TFlexDirection;
  className?: string;
}

const Row: FunctionComponent<IRowProperties> = (props) => {
  const classes = classNames(
    "Row",
    {
      [`Row--justify-${props.justify}`]: !!props.justify,
      [`Row--align-${props.align}`]: !!props.align,
      [`Row--grow`]: !!props.grow,
      [`Row--direction-${props.direction}`]: !!props.direction,
    },
    props.className
  );

  return <div className={classes}> {props.children}</div>;
};

export default Row;
