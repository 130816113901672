import gql from "graphql-tag";
import { formatMultiple } from "../../../utils/formatNumbers";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import {
  PRIMARY_INDIGO,
  PRIMARY_PINK,
  PRIMARY_YELLOW,
  PRIMARY_INDIGO_TRANS,
  SECONDARY_ORANGE,
} from "../../../utils/colors";

export const fundDPIByVintage: PlotSpec = {
  query: gql`
    query fundDPIByVintage(
      $filters: fundsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      funds(
        dimensions: ["vintageYear"]
        filters: $filters
        sorted: true
        removeCensored: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        vintageYear
        avgDPI
        dpiQ1: dpiQuantile(q: 0.75)
        dpiQ2: dpiQuantile(q: 0.50)
        dpiQ3: dpiQuantile(q: 0.25)
        numFunds
        censoringInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "scatter",
        x: data.funds.vintageYear,
        y: data.funds.avgDPI,
        hovertext: data.funds.avgDPI.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "Avg DPI",
        marker: {
          color: SECONDARY_ORANGE,
        },
      },
      {
        type: "scatter",
        x: data.funds.vintageYear,
        y: data.funds.dpiQ1,
        hovertext: data.funds.dpiQ1.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "DPI Q1",
        marker: {
          color: PRIMARY_PINK,
        },
      },
      {
        type: "scatter",
        x: data.funds.vintageYear,
        y: data.funds.dpiQ2,
        hovertext: data.funds.dpiQ2.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "DPI Q2",
        marker: {
          color: PRIMARY_INDIGO,
        },
      },
      {
        type: "scatter",
        x: data.funds.vintageYear,
        y: data.funds.dpiQ3,
        hovertext: data.funds.dpiQ3.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "DPI Q3",
        marker: {
          color: PRIMARY_YELLOW,
        },
      },
      {
        type: "bar",
        x: data.funds.vintageYear,
        y: data.funds.numFunds,
        yaxis: "y2",
        hovertext: data.funds.numFunds,
        hoverinfo: "x+text+name",
        name: "Number of Funds",
        marker: {
          color: PRIMARY_INDIGO_TRANS,
        },
      },
    ],
    layout: {
      ...getDefaultLayout("year"),
      yaxis2: { side: "right", overlaying: "y" },
    },
  }),
  censoringChecker: (data) => data.funds.censoringInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: [
      "Year",
      "Avg DPI",
      "DPI Q1",
      "DPI Q2",
      "DPI Q3",
      "Number of Funds",
    ],
    data: [
      data.funds.vintageYear,
      data.funds.avgDPI.map(formatMultiple),
      data.funds.dpiQ1.map(formatMultiple),
      data.funds.dpiQ2.map(formatMultiple),
      data.funds.dpiQ3.map(formatMultiple),
      data.funds.numFunds,
    ],
  }),
};
