import React, { Component, RefObject } from "react";
import * as ReactDOM from "react-dom";
import classNames from "classnames";

import { SelectItem } from "./SelectItem";

import "./Select.scss";

interface ISelectProps {
  id?: string;
  placeholder?: string;
  options: readonly string[];
  selected: string[];
  onSelect: (value: string) => void;
  clearSearchInput: boolean;
  withSelectAllOption: boolean;
  onSelectAllFilter?: (values: string[]) => void;
  onUnselectAllFilter?: () => void;
}

interface ISelectState {
  isOpen: boolean;
  itemPosition: number;
  itemsLength: number;
  searchValue: string;
}

type State = ISelectState;
type Props = ISelectProps;

const SELECT_ALL = "Select all";
const UNSELECT_ALL = "Unselect all";
const KEYS_CODE = {
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  END: 35,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
};

export class Select extends Component<Props, State> {
  static defaultProps = {
    withSelectAllOption: true,
  };
  static getDerivedStateFromProps(props: Props) {
    if (props.clearSearchInput) {
      return {
        searchValue: "",
      };
    }
    return null;
  }

  private readonly input: RefObject<HTMLInputElement>;
  private readonly dropDown: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.changeOpen = this.changeOpen.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.input = React.createRef();
    this.dropDown = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      isOpen: false,
      itemPosition: -1,
      itemsLength: 0,
      searchValue: "",
    };
  }

  inputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    this.setState({ searchValue: e.currentTarget.value });
  }

  changeOpen(val: boolean) {
    this.setState({ isOpen: val });
    if (!val) {
      this.setState({
        itemPosition: -1,
      });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
    if (this.dropDown && this.dropDown.current) {
      this.dropDown.current.addEventListener("keydown", this.onKeyPress);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
    if (this.dropDown && this.dropDown.current) {
      this.dropDown.current.removeEventListener("keydown", this.onKeyPress);
    }
  }

  handleClickOutside(event: any) {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }

  optionsToRender = (withSelectAllOption: boolean) => {
    const { options, selected } = this.props;
    const inputSearch = this.input.current?.value?.toLowerCase() ?? "";
    const selectAllNone = withSelectAllOption
      ? [selected.length === 0 ? SELECT_ALL : UNSELECT_ALL]
      : [];
    const maybeFilteredOptions =
      inputSearch.length === 0
        ? options
        : options.filter((item) => item.toLowerCase().includes(inputSearch));
    return selectAllNone.concat(maybeFilteredOptions);
  };

  onKeyPress = (e: any) => {
    const { itemPosition } = this.state;

    switch (e.keyCode) {
      case KEYS_CODE.TAB:
        this.changeOpen(false);
        break;
      case KEYS_CODE.ENTER:
        if (this.state.isOpen) {
          const options = this.optionsToRender(this.props.withSelectAllOption);
          const value = options[itemPosition];

          switch (value) {
            case SELECT_ALL:
              this.props.onSelectAllFilter?.(this.optionsToRender(false));
              break;
            case UNSELECT_ALL:
              this.props.onUnselectAllFilter?.();
              break;
            default:
              this.props.onSelect(value);
              break;
          }
        }
        break;
      case KEYS_CODE.ESCAPE:
        this.changeOpen(false);
        break;
      case KEYS_CODE.END:
        this.setState({
          itemPosition: 0,
        });
        break;
      case KEYS_CODE.UP_ARROW:
        if (itemPosition > 0) {
          this.setState({
            itemPosition: itemPosition - 1,
          });
        }
        break;
      case KEYS_CODE.DOWN_ARROW:
        if (
          itemPosition <
          this.optionsToRender(this.props.withSelectAllOption).length - 1
        ) {
          this.setState({
            itemPosition: itemPosition + 1,
          });
        }
        break;
    }
  };

  renderSelectOption(option: string, key: number) {
    const {
      selected,
      onSelect,
      onSelectAllFilter,
      onUnselectAllFilter,
    } = this.props;
    const { itemPosition } = this.state;
    const optionSelected = selected.includes(option);

    let selectHandler = onSelect;

    if (option === SELECT_ALL) {
      selectHandler = () => onSelectAllFilter?.(this.optionsToRender(false));
    } else if (option === UNSELECT_ALL) {
      selectHandler = () => onUnselectAllFilter?.();
    }

    return (
      <SelectItem
        optionKeyDown={key === itemPosition}
        optionSelected={optionSelected}
        handleClick={selectHandler}
        value={option}
        key={key}
      />
    );
  }

  render() {
    const { selected } = this.props;
    const { isOpen } = this.state;
    const selectClass = classNames("Select", {});
    const dropdownClass = classNames("Select__dropdown", {
      "Select__dropdown--open": isOpen,
    });
    const options = this.optionsToRender(this.props.withSelectAllOption);

    return (
      <div ref={this.dropDown} tabIndex={-1} className={selectClass}>
        <input
          id={this.props.id}
          className="Select__input"
          onChange={this.inputChange}
          onFocus={() => this.changeOpen(true)}
          autoComplete="off"
          type="text"
          ref={this.input}
          value={this.state.searchValue}
          placeholder={
            selected.length === 0
              ? "(All)"
              : selected.length === 1
              ? selected[0]
              : `(${selected.length} Items)`
          }
        />
        <div
          onClick={() => this.changeOpen(!isOpen)}
          className="Select__addon"
        />
        <div className={dropdownClass}>
          {options.length > 1 ? (
            options.map((option: string, i: number) =>
              this.renderSelectOption(option, i)
            )
          ) : (
            <div className="Select__empty">No items found</div>
          )}
        </div>
      </div>
    );
  }
}
