import ApolloClient from "apollo-boost";

import { Auth } from "aws-amplify";

const clientGraphQL = new ApolloClient({
  uri: process.env.REACT_APP_DATA_SERVICE_URL,
  request: async (op) => {
    const user = await Auth.currentSession();
    const headers = {
      Authorization: `Bearer ${user.getIdToken().getJwtToken()}`,
    };
    op.setContext({ headers });
  },
});

export default clientGraphQL;
