import gql from "graphql-tag";

import { getDefaultLayout } from "../../../utils/defaultLayout";
import { PIE_COLORS } from "../../../utils/colors";

const labelsCompounding = ["< 4%", "< 6%", "< 8%", ">= 8%"];
const labelsMultiple = ["< 1.2x", "< 1.3x", ">= 1.3x"];

export const fundHurdleByVintage: PlotSpec = {
  query: gql`
    query fundFeesByVintage(
      $filters: fundsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      funds(
        dimensions: ["vintageYear"]
        filters: $filters
        sorted: true
        removeCensored: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        vintageYear
        c0: numFundsByHurdleRate(
          hurdleType: "Compounding"
          from: null
          to: 0.04
        )
        c1: numFundsByHurdleRate(
          hurdleType: "Compounding"
          from: 0.04
          to: 0.06
        )
        c2: numFundsByHurdleRate(
          hurdleType: "Compounding"
          from: 0.06
          to: 0.08
        )
        c3: numFundsByHurdleRate(
          hurdleType: "Compounding"
          from: 0.08
          to: null
        )
        m0: numFundsByHurdleRate(hurdleType: "Multiple", from: null, to: 0.20)
        m1: numFundsByHurdleRate(hurdleType: "Multiple", from: 0.20, to: 0.30)
        m2: numFundsByHurdleRate(hurdleType: "Multiple", from: 0.30, to: null)
        nh: numFundsByHurdleRate(hurdleType: "NoHurdle", from: null, to: null)
        datasetInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => {
    // For this stacked area chart, we need to calculate the cum sum manually for each year
    const calcTraces = (prefix: string, subplot: number, labels: string[]) => {
      let prevVal = Array<number>(data.funds.vintageYear.length).fill(0);
      return labels.map((lbl, lblIdx) => {
        // Cum sum
        const val = data.funds[`${prefix}${lblIdx}`].map(
          (elt: number, idx: number) => elt + prevVal[idx]
        );
        prevVal = val;

        // @ts-ignore
        return {
          type: "scatter",
          x: data.funds.vintageYear,
          y: val,
          fill: lblIdx === 0 ? "tozeroy" : "tonexty",
          hovertext: data.funds[`${prefix}${lblIdx}`],
          hoverinfo: "x+name+text",
          mode: "lines",
          name: lbl,
          xaxis: `x${subplot}`,
          yaxis: `y${subplot}`,
          marker: {
            color: PIE_COLORS[lblIdx],
          },
        } as Plotly.Data;
      });
    };

    const traces = [
      ...calcTraces("c", 1, labelsCompounding),
      ...calcTraces("m", 2, labelsMultiple),
    ];

    return {
      data: traces,
      layout: {
        ...getDefaultLayout("year", "Number of Funds"),
        legend: {
          ...getDefaultLayout("year").legend,
          traceorder: "normal",
        },
        xaxis: { title: "Compound Hurdles" },
        xaxis2: { title: "Multiple Hurdles" },
        grid: { rows: 1, columns: 2, pattern: "independent" },
        yaxis2: {
          gridcolor: "rgba(0,0,0,0)",
          fixedrange: true,
          title: "Number of Funds",
        },
      },
    };
  },
  censoringChecker: (data) => data.funds.datasetInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Year", "None"].concat(labelsCompounding).concat(labelsMultiple),
    data: [
      data.funds.vintageYear.map((e: string) => e.slice(-2)),
      data.funds["nh"],
      data.funds["c0"],
      data.funds["c1"],
      data.funds["c2"],
      data.funds["c3"],
      data.funds["m0"],
      data.funds["m1"],
      data.funds["m2"],
    ],
  }),
};
