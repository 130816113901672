import React from "react";

const CookiePolicy = () => (
  <>
    <h3>Cookies on EIF - The Bench</h3>
    <em>Last updated on 20th of September, 2018</em>

    <p>
      The Bench, a service provided by the European Investment Fund (EIF) and
      available under http://thebench.eif.org, uses so-called ‘cookies’.
    </p>
    <p>
      Cookies are small text files stored on your computer or mobile device.
      They help the EIF website remember your actions and preferences during
      your visit (‘session cookies’) or over time, so you don’t have to re-enter
      information when you return to the web pages (including forms) that you
      previously consulted (‘permanent cookies’).
    </p>
    <p>
      Currently, The Bench uses cookies exclusively to implement required
      functionality, hence they cannot be disabled separately, and acceptance of
      these cookies is required for the service to work correctly.
    </p>
    <p>
      On other EIF pages, the general{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.eif.org/cookies-policy.htm"
      >
        EIF cookie policy
      </a>{" "}
      applies, and the EIF might use cookies to collect personal data. In such
      cases, the EIF processes the personal data in accordance with its{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.eif.org/privacy-policy.htm"
      >
        privacy policy.
      </a>
    </p>
    <br />
    <br />
    <h3>How does the EIF use cookies?</h3>

    <p>The Bench uses cookies in different ways, including:</p>
    <ul>
      <li>
        Remembering preferences you have chosen previously, such as language;
      </li>
      <li>Keeping your user information information while you are logged in</li>
    </ul>
    <br />
    <br />
    <h3>Cookies used by The Bench</h3>

    <table summary="strictly necessary cookies table">
      <thead>
        <tr>
          <td>Cookie Name/Local Storage Key </td>
          <td>Purpose</td>
          <td>Description of use</td>
          <td>Expiry</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>TermsOfUseModalHidden</td>
          <td>Remember terms of use choice </td>
          <td>Do not show Terms of Use modal for a one month</td>
          <td>30 days</td>
        </tr>
        <tr>
          <td>CookiePolicyBannerHidden</td>
          <td>Remember cookie policy choice </td>
          <td>Do not show cookie banner for one month</td>
          <td>30 days</td>
        </tr>
        <tr>
          <td>CognitoIdentityServiceProvider.* </td>
          <td>User login state</td>
          <td>
            Keeps user login information and allows user to remain logged in{" "}
          </td>
          <td>Until logout</td>
        </tr>
        <tr>
          <td>amplify-signin-with-hostedUI</td>
          <td>AWS Amplify setting</td>
          <td>Used for authentication</td>
          <td>Until logout</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
  </>
);

export default CookiePolicy;
