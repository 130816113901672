import classNames from "classnames";
import React, { FunctionComponent } from "react";
import "./Label.scss";

interface StyleProps extends IStyleSize, IStyleWidth {}

export interface ILabelProps extends Partial<StyleProps> {
  className?: string;
  required?: boolean;
}

export const Label: FunctionComponent<ILabelProps> = ({
  className,
  children,
  required,
  size,
}) => {
  const classes = classNames(className, "Label", {
    "Label--required": required,
    [`Label--size-${size}`]: !!size,
  });

  return <label className={classes}>{children}</label>;
};
