import * as React from "react";

import "./DataTable.scss";

export const DataTable = ({ headers, data, headerCols = [0] }: TTableData) => {
  const rows =
    data.length > 0 ? data[0].map((col, i) => data.map((row) => row[i])) : [];
  const header = headers.map((headerRow, index) => (
    <th key={index}>{headerRow}</th>
  ));
  const body = rows.map((bodyRow: TStringOrNumber[], bodyRowIndex: number) => {
    return (
      <tr key={bodyRowIndex}>
        {bodyRow.map((rowCell: TStringOrNumber, rowCellIndex: number) => {
          return (
            <td
              key={rowCellIndex}
              className={headerCols.includes(rowCellIndex) ? "headerCol" : ""}
            >
              {rowCell}
            </td>
          );
        })}
      </tr>
    );
  });

  return (
    <table className="DataTable">
      <thead className="DataTable__header">
        <tr>{header}</tr>
      </thead>
      <tbody className="DataTable__body">{body}</tbody>
    </table>
  );
};
