import * as React from "react";
import classNames from "classnames";

import "./Header.scss";
import { Icon } from "../Icon";

interface ComponentProps {
  align?: "left" | "center" | "right";
  color?: TStyleColor;
  displayIcon?: boolean;
  italic?: boolean;
  nowrap?: boolean;
  size?: TStyleSize;
  text: string;
  uppercase?: boolean;
}

export const Header: React.FunctionComponent<ComponentProps> = ({
  align,
  children,
  color,
  displayIcon,
  italic,
  nowrap,
  size,
  text,
  uppercase,
}) => {
  const headerClass = classNames("Header", {
    [`Header--size-${size}`]: !!size,
    [`Header--color-${color}`]: !!color,
    [`Header--align-${align}`]: !!align,
    "Header--uppercase": !!uppercase,
    "Header--italic": !!italic,
    "Header--nowrap": !!nowrap,
  });

  return (
    <div className={headerClass}>
      {!!displayIcon && (
        <Icon icon="header" size={size} className="Header__icon" />
      )}
      {text}
      {children}
    </div>
  );
};

Header.defaultProps = {
  size: "md",
};
