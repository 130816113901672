export const getDefaultLayout = (
  xAxisType: "default" | "year" = "default",
  yAxisTitle: string | undefined = undefined
): PlotlyDef["layout"] => {
  return {
    plot_bgcolor: "rgba(255, 255, 255, 0)",
    paper_bgcolor: "rgba(255, 255, 255, .7)",
    margin: { t: 20, r: 20, b: 40, l: yAxisTitle ? 50 : 30 },
    height: 450,
    legend: {
      orientation: "h",
      x: 0,
      y: 1.1,
    },
    font: {
      family: "'Canela Text', sans-serif",
      size: 14,
    },
    hoverlabel: {
      font: {
        family: "'Canela Text', sans-serif",
        size: 14,
      },
      namelength: -1,
    },
    xaxis: {
      gridcolor: "rgba(0,0,0,.1)",
      fixedrange: true,
      ...(xAxisType === "year"
        ? {
            type: "date",
            dtick: "M12",
            tickformat: "%Y",
          }
        : {}),
    },
    yaxis: {
      gridcolor: "rgba(0,0,0,0)",
      fixedrange: true,
      title: yAxisTitle,
    },
  };
};
