import classNames from "classnames";
import React, {
  FunctionComponent,
  useCallback,
  useState,
  InputHTMLAttributes,
} from "react";
import "./Input.scss";

interface IValidation {
  isValid?: boolean;
}

interface ComponentProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">,
    IValidation {
  onChange?: (value: any) => void;
}

const useInputValue = (
  initialValue: ComponentProps["value"],
  callback: ComponentProps["onChange"]
) => {
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback(
    (event) => {
      setValue(event.currentTarget.value);
      if (callback) {
        callback({ [event.currentTarget.name]: event.currentTarget.value });
      }
    },
    [callback]
  );

  return {
    value,
    onChange,
  };
};

export const Input: FunctionComponent<ComponentProps> = ({
  onChange,
  value,
  className,
  isValid,
  ...inputProps
}) => {
  const inputClasses = classNames(
    "Input",
    { "Input--warning": !isValid },
    className
  );
  return (
    <input
      className={inputClasses}
      {...useInputValue(value, onChange)}
      {...inputProps}
    />
  );
};
