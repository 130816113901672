import React, { FunctionComponent } from "react";
import classNames from "classnames";

interface IProps {
  className?: string;
}

const Container: FunctionComponent<IProps> = ({ className, children }) => {
  const classes = classNames("Container", className);
  return <div className={classes}> {children}</div>;
};

export default Container;
