import gql from "graphql-tag";

import { getDefaultLayout } from "../../../utils/defaultLayout";
import { PIE_COLORS } from "../../../utils/colors";

// There are no fractional percent carry values, so we consider the carry
// to be in "percentage point integer space". Hence the labels
const labels = ["Carry < 20%", "Carry = 20%", "Carry > 20%"];

export const fundCarryByVintage: PlotSpec = {
  query: gql`
    query fundFeesByVintage(
      $filters: fundsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      funds(
        dimensions: ["vintageYear"]
        filters: $filters
        sorted: true
        removeCensored: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        vintageYear
        b0: numFundsByCarriedInterestRate(from: null, to: 0.20)
        b1: numFundsByCarriedInterestRate(from: 0.20, to: 0.21)
        b2: numFundsByCarriedInterestRate(from: 0.21, to: null)
        datasetInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => {
    // For this stacked area chart, we need to calculate the cum sum manually for each year
    let prevVal = Array<number>(data.funds.vintageYear.length).fill(0);
    const traces = labels.map((lbl, lblIdx) => {
      // Cum sum
      const val = data.funds[`b${lblIdx}`].map(
        (elt: number, idx: number) => elt + prevVal[idx]
      );
      prevVal = val;

      // @ts-ignore
      return {
        type: "scatter",
        x: data.funds.vintageYear,
        y: val,
        fill: lblIdx === 0 ? "tozeroy" : "tonexty",
        hovertext: data.funds[`b${lblIdx}`],
        hoverinfo: "x+name+text",
        mode: "lines",
        name: lbl,
        marker: {
          color: PIE_COLORS[lblIdx],
        },
      } as Plotly.Data;
    });

    return {
      data: traces,
      layout: {
        ...getDefaultLayout("year", "Number of Funds"),
        legend: {
          ...getDefaultLayout("year").legend,
          traceorder: "normal",
        },
      },
    };
  },
  censoringChecker: (data) => data.funds.datasetInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Year"].concat(labels),
    data: [
      data.funds.vintageYear,
      data.funds["b0"],
      data.funds["b1"],
      data.funds["b2"],
    ],
  }),
};
