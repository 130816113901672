import React from "react";
import "./PageFooter.scss";
import { Col, Container, Row } from "../../Grid";

interface PageFooterProps {
  showTermsOfUse: () => void;
  showPrivacyNotice: () => void;
  showCookiePolicy: () => void;
}

export const PageFooter = (props: PageFooterProps) => {
  return (
    <div className="PageFooter">
      <Container>
        <Row>
          <Col size={{ sm: 12, md: 12, lg: 12, xl: 12 }}>
            <h5 className="PageFooter__header --text-color-secondary">
              The Bench
            </h5>
            <ul className="PageFooter__content-list">
              <li>
                <a href="mailto:thebench@eif.org">Contact Us</a>
              </li>
              <li>
                <button onClick={props.showTermsOfUse}>Terms of Use</button>
              </li>
              <li>
                <button onClick={props.showPrivacyNotice}>
                  Privacy Notice
                </button>
              </li>
              <li>
                <button onClick={props.showCookiePolicy}>Cookie Policy</button>
              </li>
              <li>
                <a href="https://www.eif.org">European Investment Fund</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="PageFooter__bottom">
        <Container>
          <Row justify="space-between">
            <Col>Copyright © European Investment Fund 2019</Col>
            <Col>
              The European Investment Fund is not responsible for the content of
              external internet sites.
            </Col>
            <Col>
              <a href="mailto:thebench@eif.org">&#9993; Contact us</a>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
