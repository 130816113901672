import React, { FormEvent } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { RootState } from "../../../../store";
import {
  IConfirmNewPassword,
  resetPasswordRequest,
  resetPasswordSubmit,
} from "../../../../data/session";
import { checkValidity, defaultInputState } from "../../../../utils/formData";

import { Button } from "../../../Button";
import { IInputValidation, Group, Input } from "../../../Form";
import Spinner from "../../../Spinner";
import { sessionSelector } from "../../../../data/session";

interface ComponentProps {
  backToLogin: () => void;
}

interface MapStateProps {
  working: boolean;
  error?: {
    message: string;
  };
  recoveryCodeSend: boolean;
  passwordResetRequired: boolean;
}

const mapStateToProps = (state: RootState) => {
  const {
    working,
    error,
    recoveryCodeSend,
    passwordResetRequired,
  } = sessionSelector(state);
  return {
    working,
    error,
    recoveryCodeSend,
    passwordResetRequired,
  };
};

interface MapDispatchProps {
  resetPasswordRequest: (userName: string) => Promise<any>;
  resetPasswordSubmit: (data: IConfirmNewPassword) => Promise<any>;
}

const actionCreators = { resetPasswordRequest, resetPasswordSubmit };

interface State {
  username: IInputValidation;
  code: IInputValidation;
  password: IInputValidation;
  resetPasswordCompleted: boolean;
}

type Props = ComponentProps & MapStateProps & MapDispatchProps;

class ForgotPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      username: {
        ...defaultInputState,
        validation: {
          isEmail: true,
          required: true,
        },
      },
      code: defaultInputState,
      password: defaultInputState,
      resetPasswordCompleted: false,
    };
  }

  handleSubmit = async (e: FormEvent) => {
    const {
      resetPasswordSubmit,
      resetPasswordRequest,
      recoveryCodeSend,
    } = this.props;
    const { username, code, password } = this.state;

    e.preventDefault();
    if (recoveryCodeSend) {
      const formData = {
        username: username.value,
        code: code.value,
        password: password.value,
      };
      try {
        await resetPasswordSubmit(formData);
        this.setState({ resetPasswordCompleted: true });
      } catch (e) {
        console.warn(`Could not complete password reset: ${e.message}`);
      }
    } else {
      resetPasswordRequest(username.value);
    }
  };

  handleChange = (value: { [key: string]: string }) => {
    const currentField = Object.keys(value)[0];
    this.setState({
      [currentField]: {
        ...this.state[currentField],
        value: value[currentField],
        valid: checkValidity(
          value[currentField],
          this.state[currentField].validation
        ),
        touched: true,
      },
    } as State);
  };

  isLoginButtonDisabled = () => {
    const { username } = this.state;

    return !username.valid || !username.touched;
  };

  isInputValid = (fieldName: string) => {
    const { valid, touched } = this.state[fieldName];

    return valid || !touched;
  };

  render() {
    const {
      working,
      error,
      recoveryCodeSend,
      backToLogin,
      passwordResetRequired,
    } = this.props;
    const { resetPasswordCompleted, username, code, password } = this.state;
    const classes = classNames("LoginForm", {
      "LoginForm--loading": !!working,
    });

    return !resetPasswordCompleted ? (
      <form onSubmit={this.handleSubmit} method="post" className={classes}>
        <Spinner centered show={working}>
          {error && (
            <p className="LoginForm__unauthenticated">{error.message}</p>
          )}
          {passwordResetRequired && (
            <p className="LoginForm__change_password">
              A password reset was required by an administrator
            </p>
          )}
          {recoveryCodeSend && (
            <p className="LoginForm__change_password">
              A verification code was sent to your Email address
            </p>
          )}
          <Group required label="Username" size="sm">
            <Input
              name="username"
              autoComplete="username"
              placeholder="Username"
              value={username.value}
              isValid={this.isInputValid("username")}
              onChange={this.handleChange}
            />
            {!this.isInputValid("username") && (
              <span className="LoginForm__error">Username is invalid</span>
            )}
          </Group>
          {recoveryCodeSend && (
            <>
              <Group required label="Code" size="sm">
                <Input
                  name="code"
                  type="number"
                  autoComplete="one-time-code"
                  placeholder="Code"
                  value={code.value}
                  isValid={this.isInputValid("code")}
                  onChange={this.handleChange}
                />
                {!this.isInputValid("code") && (
                  <span className="LoginForm__error">Code is required</span>
                )}
              </Group>
              <Group required label="New Password" size="sm">
                <Input
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={password.value}
                  isValid={this.isInputValid("password")}
                  onChange={this.handleChange}
                />
                {!this.isInputValid("password") && (
                  <span className="LoginForm__error">Password is required</span>
                )}
              </Group>
            </>
          )}
          <Button
            size="lg"
            type="submit"
            disabled={this.isLoginButtonDisabled()}
          >
            {recoveryCodeSend ? "Submit" : "Reset Password"}
          </Button>
        </Spinner>
      </form>
    ) : (
      <form className="LoginForm LoginForm--password_changed">
        <p>Password changed</p>
        <Button size="lg" onClick={backToLogin}>
          Back to login
        </Button>
      </form>
    );
  }
}

export default connect(mapStateToProps, actionCreators)(ForgotPassword);
