import {
  IActionBody,
  IReduxActionCreator,
  PromiseAction,
  PromiseActionCreator,
} from "./types";

export const createActionCreator = <P = undefined>(
  type: string
): IReduxActionCreator<P> =>
  Object.assign(
    (params?: IActionBody<P>) => {
      if (params) {
        return { type, ...params };
      }
      return { type };
    },
    { actionType: type }
  );

/**
 * Creates an action creator based on a `Promise` factory. Actions for launch, success
 * and failure will be dispatched to track the promise.
 * @param type the `Action` type prefix
 * @param factory produces the promises based on its arguments
 * @returns an action creator
 */
export const createPromiseActionCreator = <R>(
  type: string,
  factory: (...args: any[]) => Promise<R>
): PromiseActionCreator<R> => {
  const actionCreators = {
    started: createActionCreator<R>(`${type}_STARTED`),
    success: createActionCreator<R>(`${type}_SUCCESS`),
    failure: createActionCreator<R>(`${type}_FAILURE`),
  };

  const actionCreator = (...args: any[]): PromiseAction<R> => async (
    dispatch
  ) => {
    dispatch(actionCreators.started());

    try {
      const payload = await factory(...args);
      dispatch(actionCreators.success({ payload }));
      return payload;
    } catch (error) {
      dispatch(actionCreators.failure({ error }));
      throw error;
    }
  };

  return Object.assign(actionCreator, {
    actionStartedType: actionCreators.started.actionType,
    actionSuccessType: actionCreators.success.actionType,
    actionFailureType: actionCreators.failure.actionType,
  });
};
