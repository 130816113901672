import * as React from "react";
import { RefObject } from "react";
import { LAPTOP_SCREEN_SIZE } from "../responsiveUtilities";
import { Location, History, UnregisterCallback } from "history";

export interface IScrollingToProps {
  history: History;
  location: Location;
  filtersRef: RefObject<HTMLDivElement>;
}

export interface IComponentReferences {
  [key: string]: RefObject<HTMLDivElement>;
}

interface IInjectedProps {
  setReferences: (references: IComponentReferences) => void;
}

function withScrollingToComponent<P extends IInjectedProps>(
  WrappedComponent: React.ComponentType<P>
) {
  // Exclude the injected members, and add the additional props
  type Props = Pick<P, Exclude<keyof P, keyof IInjectedProps>> &
    IScrollingToProps;

  return class extends React.Component<Props> {
    componentReferences: IComponentReferences = {};
    unregisterHistoryListerer: UnregisterCallback = () => undefined;

    setReferences = (componentReferences: IComponentReferences) => {
      this.componentReferences = componentReferences;
    };

    scrollToAnchor = (loc: Location) => {
      const {
        props: { filtersRef },
        componentReferences,
      } = this;

      const plotAnchor = loc.hash.slice(1);
      const componentRef = componentReferences[plotAnchor]
        ? componentReferences[plotAnchor].current
        : null;

      if (componentRef) {
        filtersRef.current &&
        filtersRef.current.clientWidth > LAPTOP_SCREEN_SIZE &&
        componentReferences[plotAnchor]
          ? window.scrollTo(
              0,
              componentRef.offsetTop - filtersRef.current.offsetHeight
            )
          : window.scrollTo(0, componentRef.offsetTop);
      }
    };

    componentDidMount() {
      this.unregisterHistoryListerer = this.props.history.listen(
        this.scrollToAnchor
      );
      this.scrollToAnchor(this.props.location);
    }

    componentWillUnmount() {
      this.unregisterHistoryListerer();
    }

    render() {
      const { history, location, filtersRef, ...rest } = this.props;
      // Looks like the correct type cannot be inferred in the current form
      const props = ({
        ...rest,
        setReferences: this.setReferences,
      } as unknown) as P;

      return <WrappedComponent {...props} />;
    }
  };
}

export default withScrollingToComponent;
