import gql from "graphql-tag";

import { formatMultiple } from "../../../utils/formatNumbers";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import {
  PRIMARY_INDIGO,
  PRIMARY_PINK,
  PRIMARY_YELLOW,
  SECONDARY_ORANGE,
} from "../../../utils/colors";

export const investmentMocByInvestmentYear: PlotSpec = {
  query: gql`
    query investmentMocByInvestmentYear(
      $filters: investmentsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      investments(
        dimensions: ["investmentYear"]
        filters: $filters
        sorted: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        investmentYear
        avgMoc
        mocQ1: mocQuantile(q: 0.75)
        mocQ2: mocQuantile(q: 0.50)
        mocQ3: mocQuantile(q: 0.25)
        censoringInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "scatter",
        x: data.investments.investmentYear,
        y: data.investments.avgMoc,
        hovertext: data.investments.avgMoc.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "Avg MoC",
        marker: {
          color: PRIMARY_PINK,
        },
      },
      {
        type: "scatter",
        x: data.investments.investmentYear,
        y: data.investments.mocQ1,
        hovertext: data.investments.mocQ1.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "MoC Q1",
        marker: {
          color: PRIMARY_INDIGO,
        },
      },
      {
        type: "scatter",
        x: data.investments.investmentYear,
        y: data.investments.mocQ2,
        hovertext: data.investments.mocQ2.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "MoC Q2",
        marker: {
          color: PRIMARY_YELLOW,
        },
      },
      {
        type: "scatter",
        x: data.investments.investmentYear,
        y: data.investments.mocQ3,
        hovertext: data.investments.mocQ3.map(formatMultiple),
        hoverinfo: "x+text+name",
        mode: "lines+markers",
        name: "MoC Q3",
        marker: {
          color: SECONDARY_ORANGE,
        },
      },
    ],
    layout: {
      ...getDefaultLayout("year"),
    },
  }),
  censoringChecker: (data) => data.investments.censoringInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Year", "Avg MoC", "MoC Q1", "MoC Q2", "MoC Q3"],
    data: [
      data.investments.investmentYear,
      data.investments.avgMoc.map(formatMultiple),
      data.investments.mocQ1.map(formatMultiple),
      data.investments.mocQ2.map(formatMultiple),
      data.investments.mocQ3.map(formatMultiple),
    ],
  }),
};
