import gql from "graphql-tag";
import clientGraphQL from "../../utils/clientGraphQL";

export const fetchFilters = async () => {
  const query = gql`
    {
      vintageYear: funds(dimensions: ["vintageYear"], sorted: true) {
        values: vintageYear
      }
      strategy: funds(dimensions: ["strategy"], sorted: true) {
        values: strategy
      }
      sectorGroup: funds(dimensions: ["sectorGroup"], sorted: true) {
        values: sectorGroup
      }
      stageGroup: funds(dimensions: ["stageGroup"], sorted: true) {
        values: stageGroup
      }
      teamLocation: funds(dimensions: ["teamLocation"], sorted: true) {
        values: teamLocation
      }
      investmentYear: investments(
        dimensions: ["investmentYear"]
        sorted: true
      ) {
        values: investmentYear
      }
      investmentSectorGroup: investments(
        dimensions: ["investmentSectorGroup"]
        sorted: true
      ) {
        values: investmentSectorGroup
      }
      investmentStageGroup: investments(
        dimensions: ["investmentStageGroup"]
        sorted: true
      ) {
        values: investmentStageGroup
      }
      investmentCountry: investments(
        dimensions: ["investmentCountry"]
        sorted: true
      ) {
        values: investmentCountry
      }
      countryGroup: vcSurvey(dimensions: ["countryGroup"], sorted: true) {
        values: countryGroup
      }
      sector: vcSurvey(dimensions: ["sector"], sorted: true) {
        values: sector
      }
      stageFocus: vcSurvey(dimensions: ["stageFocus"], sorted: true) {
        values: stageFocus
      }
      surveyYear: vcSurvey(dimensions: ["surveyYear"], sorted: true) {
        values: surveyYear
      }
    }
  `;
  return await clientGraphQL.query({ query });
};
