import React, { FunctionComponent } from "react";

import { ILabelProps, Label } from "../Label";
import "./Group.scss";

interface Styles extends IStyleWidth, IStyleSize, IStyleFlexGrow {}

interface GroupProps extends Partial<Styles>, ILabelProps {
  label?: string;
}

export const Group: FunctionComponent<GroupProps> = ({
  label,
  size,
  required,
  children,
}) => {
  return (
    <div className="Group">
      <Label required={required} size={size}>
        {label}
      </Label>
      {children}
    </div>
  );
};
