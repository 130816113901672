import { IReduxAsyncState, SimpleAction } from "./types";

export const startedActionHandler = <P>(
  state: IReduxAsyncState<P>,
  action: SimpleAction<P>
): IReduxAsyncState<P> => {
  return Object.assign({}, state, { working: true });
};

export const successActionHandler = <P>(
  state: IReduxAsyncState<P>,
  action: SimpleAction<P>
): IReduxAsyncState<P> => {
  return Object.assign({}, state, { payload: action.payload, working: false });
};

export const failureActionHandler = <P>(
  state: IReduxAsyncState<P>,
  action: SimpleAction<P>
): IReduxAsyncState<P> => {
  return Object.assign({}, state, { error: action.error, working: false });
};
