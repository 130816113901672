import { IError } from "../../components/Login/components/LoginForm/LoginForm";
import { RootState } from "../../store";

export const sessionSelector = (state: RootState) => ({
  ...state.session.payload,
  recoveryCodeSend:
    (state.session.payload && state.session.payload.recoveryCodeSend) || false,
  working: state.session.working,
  error: (state.session.payload && state.session.error) as IError,
});

export const permissionsSelector = (state: RootState) =>
  sessionSelector(state).permissions;
