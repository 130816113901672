import React from "react";

const PrivacyNotice = () => (
  <>
    <h3>Privacy Notice on EIF - The Bench</h3>
    <em>Last updated on 15th of October, 2019</em>
    <br />
    Personal data are processed by the European Investment Fund (“EIF” or{" "}
    <em>“Controller”</em>) in accordance with Regulation (EU) 2018/1725 on the
    protection of natural persons with regard to the processing of personal data
    by the Union institutions, bodies, offices and agencies and on the free
    movement of such data, as such regulation may be amended, restated, or
    supplemented from time to time.
    <br />
    <br />
    <h3>1. Legal basis and purpose of the processing</h3>
    Article 2 of the EIF Statutes obliges EIF to “contribute to the pursuit of
    the objectives of the European Union”. With a view to fulfilling its
    referred tasks, EIF provides financial guarantees and equity investments to
    financial intermediaries and fund managers for the final benefit of small-
    and medium sized enterprises (SMEs). In this context, the EIF is entrusted
    with a variety of mandates from third parties (EIF Mandators), which may
    contain specific policy guidelines to the transactions financed under such
    mandates. The EIF provides information services about all its activities to
    the public at large, thus increasing transparency and contributing to an
    understanding of the EIF’s activities. The EIF also foresees the possibility
    to fill-in contact form. In order to be able to provide the necessary
    information and/or answer questions from the public at large, the EIF
    collects personal data related to the visitors of its websites as further
    detailed below.
    <br />
    <br />
    <h3>2. Data subjects</h3>
    Any visitors of the EIF – The Bench website, available at
    https://thebench.eif.org/
    <br />
    <br />
    <h3>3. Data recipients</h3>
    For the ‘contact us’ part: EIF -{" "}
    <a href="mailto:thebench@eif.org">thebench@eif.org</a>
    <br />
    <br />
    <h3>4. Category of data</h3>
    Personal data processed by the EIF consist of:
    <ul>
      <li>
        data provided spontaneously by the data subjects to obtain access to the
        website (e.g. email address, name and surname);
      </li>
      <li>
        data disclosed spontaneously by the data subjects in their
        communications to the webmaster (if any);
      </li>
      <li>
        data generated by the data subjects incidentally through their use of
        the website (e.g., server access logs, query logs);
      </li>
      <li>certain cookies in accordance with its cookie policy.</li>
    </ul>
    <br />
    <br />
    <h3>5. Data retention period</h3>
    Any personal data collected and processed in the context herein will be
    retained by the EIF throughout the validity of the data subjects’ website
    access credentials, except for the incidental data, which might be retained
    for up to two years more.
    <br />
    <br />
    <h3>6. Rights of data subjects</h3>
    <ul>
      <li>
        Data subjects have the right to obtain from the controller confirmation
        as to whether or not personal data concerning them are being processed,
        and, where that is the case, access their personal data by contacting
        the Controller (info@eif.org) or through the EIF DPO (dpo@eif.org) and
        they will receive a response without undue delay (
        <em>right of access</em>);
      </li>
      <li>
        Data subjects have the right to obtain from the controller without undue
        delay the rectification of any inaccurate personal data concerning them
        (<em>right to rectification</em>);
      </li>
      <li>
        Data subjects have the right to obtain from the controller the erasure
        of their personal data when they are no longer necessary in relation to
        the purposes for which they were collected (<em>right to erasure</em>);
      </li>
      <li>
        Data subjects have the right to obtain from the controller restriction
        of processing when (i) they contest the accuracy of their personal data,
        (ii) the processing is unlawful, (iii) the controller no longer needs
        the personal data for the purposes of the processing or (iv) they have
        objected to processing, pending the verification whether the legitimate
        grounds of the controller override theirs (
        <em>right to restriction of processing</em>);
      </li>
      <li>
        Data subjects have the right to object, on grounds relating to their
        particular situation, when the processing of personal data is unlawful;
      </li>
      <li>
        Data subjects have the right to receive from the controller their
        personal data in a structured, commonly used and machine-readable format
        and transmit those data to another controller without hindrance from the
        controller to which the personal data have been provided (
        <em>right to data portability</em>);
      </li>
      <li>
        Data subjects may lodge a complaint to the European Data Protection
        Supervisor (www.edps.europa.eu) at any time (
        <em>right to lodge a complaint</em>).
      </li>
    </ul>
  </>
);

export default PrivacyNotice;
