import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors } from "../../../../data/filters";
import { RootState } from "../../../../store";
import { BenchmarkingPlotGQL } from "../../../../components/BenchmarkingPlot";
import { Header } from "../../../../components/Header";
import withScrollingToComponent, {
  IComponentReferences,
} from "../../../../utils/HOC/withScrollingToComponent";
import { PlotTabInnerProps } from "../types";
import { getGQLFields } from "../../../../data/plots/vcsurvey";

function mapStateToProps(state: RootState) {
  return selectors(state);
}

class VcSurvey extends Component<PlotTabInnerProps> {
  private readonly componentReferences: IComponentReferences;

  constructor(props: PlotTabInnerProps) {
    super(props);
    this.componentReferences = {
      publications: React.createRef(),
      current: React.createRef(),
      future: React.createRef(),
      byCountryGroup: React.createRef(),
      bySector: React.createRef(),
      byStageFocus: React.createRef(),
    };

    props.setReferences(this.componentReferences);
  }

  render() {
    const { chosenFilters, vcSurveyTopic } = this.props;

    const onlyFuture = getGQLFields(vcSurveyTopic)[0] === undefined;

    return (
      <div className="Explorer__graphs-container">
        <div ref={this.componentReferences.publications}>
          <Header text={`Publications`} size="xl" displayIcon />
          <p>
            This tab allows you to play with some of the data collected by the
            EIF VC Surveys of 2018 and 2019.
          </p>
          <p>
            For a detailed analysis, please have a look at our survey-related
            working papers, produced by the EIF’s Research &amp; Market Analysis
            division:
          </p>
          <p>
            <a
              href="https://www.eif.org/news_centre/publications/EIF_Working_Paper_2019_59.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              - EIF VC Survey 2019 - Fund managers’ market sentiment and policy
              recommendations, EIF Working Paper 2019/059
            </a>
          </p>
          <p>
            <a
              href="https://www.eif.org/news_centre/publications/EIF_Working_Paper_2018_51.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              - EIF VC Survey 2018 - Fund managers’ perception of EIF’s Value
              Added, EIF Working Paper 2018/051
            </a>
          </p>
          <p>
            <a
              href="https://www.eif.org/news_centre/publications/EIF_Working_Paper_2018_48.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              - EIF VC Survey 2018 - Fund managers’ market sentiment and views
              on public intervention, EIF Working Paper 2018/48
            </a>
          </p>
          <p>
            All EIF Working Papers can be found{" "}
            <a
              href="https://www.eif.org/news_centre/research/index.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
          </p>
          <p>
            You might also be interested in our recent post:{" "}
            <a href="https://medium.com/@eif4smes/ten-things-europes-vc-managers-are-talking-about-d6b87151223b">
              Ten things Europe’s VC managers are talking about.
            </a>
          </p>
        </div>
        {onlyFuture ? null : (
          <div
            className="Explorer__graph"
            ref={this.componentReferences.current}
          >
            <Header text={`Current ${vcSurveyTopic}`} size="xl" displayIcon />
            <BenchmarkingPlotGQL
              plotID="vcSurveyCurrent"
              filters={chosenFilters}
              vcSurveyTopic={vcSurveyTopic}
            />
          </div>
        )}
        <div className="Explorer__graph" ref={this.componentReferences.future}>
          <Header text={`Future ${vcSurveyTopic}`} size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="vcSurveyFuture"
            filters={chosenFilters}
            vcSurveyTopic={vcSurveyTopic}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.byCountryGroup}
        >
          <Header
            text={`${
              onlyFuture ? "Future " : ""
            }${vcSurveyTopic} by Country Group`}
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="vcSurveyByCountryGroup"
            filters={chosenFilters}
            vcSurveyTopic={vcSurveyTopic}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.bySector}
        >
          <Header
            text={`${onlyFuture ? "Future " : ""}${vcSurveyTopic} by Sector`}
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="vcSurveyBySector"
            filters={chosenFilters}
            vcSurveyTopic={vcSurveyTopic}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.byStageFocus}
        >
          <Header
            text={`${
              onlyFuture ? "Future " : ""
            }${vcSurveyTopic} by Stage Focus`}
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="vcSurveyByStageFocus"
            filters={chosenFilters}
            vcSurveyTopic={vcSurveyTopic}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withScrollingToComponent(VcSurvey));
