import gql from "graphql-tag";
import { formatEUR } from "../../../utils/formatNumbers";
import { PIE_COLORS } from "../../../utils/colors";
import { getDefaultLayout } from "../../../utils/defaultLayout";

export const fundInvestorGroups: PlotSpec = {
  query: gql`
    query investorGroupsCommitment(
      $filters: fundsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      investors(
        dimensions: ["investorGroup"]
        filters: $filters
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        investorGroup
        commitment
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "pie",
        labels: data.investors.investorGroup,
        values: data.investors.commitment,
        hovertext: data.investors.commitment.map(formatEUR),
        hoverinfo: "text+label",
        name: "Investor Group",
        hole: 0.5,
        sort: false,
      },
    ],
    layout: {
      ...getDefaultLayout(),
      piecolorway: [...PIE_COLORS],
      annotations: [
        {
          showarrow: false,
          text: formatEUR(
            data.investors.commitment.reduce((p: number, c: number) => p + c, 0)
          ),
        },
      ],
      legend: {
        orientation: "v",
        x: 0,
        y: 1,
      },
    },
  }),
  censoringChecker: (data) => false,
  dataTable: (data) => ({
    headers: ["Investor", "Commitment"],
    data: [
      data.investors.investorGroup,
      data.investors.commitment.map(formatEUR),
    ],
  }),
};
