import React, { Component } from "react";
import { BenchmarkingPlotGQL } from "../../../../components/BenchmarkingPlot";
import { selectors } from "../../../../data/filters";
import { RootState } from "../../../../store";
import { connect } from "react-redux";
import { Header } from "../../../../components/Header";
import withScrollingToComponent, {
  IComponentReferences,
} from "../../../../utils/HOC/withScrollingToComponent";
import { PlotTabInnerProps } from "../types";

function mapStateToProps(state: RootState) {
  return selectors(state);
}

class FundPerformance extends Component<PlotTabInnerProps> {
  private readonly componentReferences: IComponentReferences;

  constructor(props: PlotTabInnerProps) {
    super(props);
    this.componentReferences = {
      fundIRRByVintage: React.createRef(),
      fundIRRBySector: React.createRef(),
      fundTVPIByVintage: React.createRef(),
      fundTVPIBySector: React.createRef(),
      fundsDPIByVintage: React.createRef(),
      fundDPIBySector: React.createRef(),
    };

    props.setReferences(this.componentReferences);
  }

  render() {
    const { chosenFilters } = this.props;
    return (
      <div className="Explorer__graphs-container">
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundIRRByVintage}
        >
          <Header text="IRR by Vintage" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="fundIRRByVintage"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundIRRBySector}
        >
          <Header text="IRR by Sector" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="fundIRRBySector"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundTVPIByVintage}
        >
          <Header text="TVPI by Vintage" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="fundTVPIByVintage"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundTVPIBySector}
        >
          <Header text="TVPI by Sector" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="fundTVPIBySector"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundsDPIByVintage}
        >
          <Header text="DPI by Vintage" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="fundDPIByVintage"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundDPIBySector}
        >
          <Header text="DPI by Sector" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="fundDPIBySector"
            filters={chosenFilters}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withScrollingToComponent(FundPerformance)
);
