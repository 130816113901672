import { createActionCreator, SimpleAction } from "../../utils/redux";

import { DisclaimerState } from "./types";
import { getItem, setItem } from "../../utils/storage";
import { AppDispatch } from "../../store";
import { Reducer } from "redux";

export const openTermsOfUse = createActionCreator("OPEN_TERMS_OF_USE");
const closeTermsOfUse = createActionCreator("CLOSE_TERMS_OF_USE");
const termsOfUseAccepted = createActionCreator("TERMS_OF_USE_ACCEPTED");
export const requireTermsOfUseAcceptance = createActionCreator(
  "REQUIRE_TERMS_OF_USE_ACCEPTANCE"
);
export const acceptTermsOfUse = () => (dispatch: AppDispatch) => {
  setItem(TERMS_ACCEPTED_COOKIE_NAME, true);
  dispatch(termsOfUseAccepted());
};
export const openPrivacyNotice = createActionCreator("OPEN_PRIVACY_NOTICE");
export const closePrivacyNotice = createActionCreator("CLOSE_PRIVACY_NOTICE");
export const openCookiePolicy = createActionCreator("OPEN_COOKIE_POLICY");
export const closeCookiePolicy = createActionCreator("CLOSE_COOKIE_POLICY");
const hideCookieBannerAction = createActionCreator("HIDE_COOKIE_BANNER");
export const hideCookieBanner = () => (dispatch: AppDispatch) => {
  setItem(COOKIE_BANNER_HIDDEN_COOKIE_NAME, true);
  dispatch(hideCookieBannerAction());
};

const TERMS_ACCEPTED_COOKIE_NAME = "TermsOfUseAccepted";
const COOKIE_BANNER_HIDDEN_COOKIE_NAME = "CookiePolicyBannerHidden";

const defaultState: DisclaimerState = {
  termsOfUseAccepted: getItem(TERMS_ACCEPTED_COOKIE_NAME) === "true",
  termsOfUseModalOpen: false,
  privacyNoticeModalOpen: false,
  cookieBannerHidden: getItem(COOKIE_BANNER_HIDDEN_COOKIE_NAME) === "true",
  cookiePolicyModalOpen: false,
};

type DisclaimerAction = SimpleAction;
export const disclaimerReducer: Reducer<DisclaimerState, DisclaimerAction> = (
  state,
  action
) => {
  const s = state || defaultState;
  switch (action.type) {
    case requireTermsOfUseAcceptance.actionType:
      return { ...s, termsOfUseModalOpen: !s.termsOfUseAccepted };
    case termsOfUseAccepted.actionType:
      return { ...s, termsOfUseAccepted: true, termsOfUseModalOpen: false };
    case openTermsOfUse.actionType:
      return { ...s, termsOfUseModalOpen: true };
    case closeTermsOfUse.actionType:
      return { ...s, termsOfUseModalOpen: false };
    case openPrivacyNotice.actionType:
      return { ...s, privacyNoticeModalOpen: true };
    case closePrivacyNotice.actionType:
      return { ...s, privacyNoticeModalOpen: false };
    case openCookiePolicy.actionType:
      return { ...s, cookiePolicyModalOpen: true };
    case closeCookiePolicy.actionType:
      return { ...s, cookiePolicyModalOpen: false };
    case hideCookieBannerAction.actionType:
      return { ...s, cookieBannerHidden: true };
    default:
      return s;
  }
};
