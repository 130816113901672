import React, { Component } from "react";
import { BenchmarkingPlotGQL } from "../../../../components/BenchmarkingPlot";
import { selectors } from "../../../../data/filters";
import { RootState } from "../../../../store";
import { connect } from "react-redux";
import { Header } from "../../../../components/Header";
import withScrollingToComponent, {
  IComponentReferences,
} from "../../../../utils/HOC/withScrollingToComponent";
import { PlotTabInnerProps } from "../types";

function mapStateToProps(state: RootState) {
  return selectors(state);
}

class FundPortfolio extends Component<PlotTabInnerProps> {
  private readonly componentReferences: IComponentReferences;

  constructor(props: PlotTabInnerProps) {
    super(props);
    this.componentReferences = {
      investmentByCompanyLocation: React.createRef(),
      investmentMocByInvestmentYear: React.createRef(),
      investmentMoCBucketsByInvestmentYear: React.createRef(),
      investmentMocBySector: React.createRef(),
    };

    props.setReferences(this.componentReferences);
  }

  render() {
    const { chosenFilters } = this.props;

    return (
      <div className="Explorer__graphs-container">
        <div
          className="Explorer__graph"
          ref={this.componentReferences.investmentByCompanyLocation}
        >
          <Header text="Investment by Company Location" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="investmentByCompanyLocation"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.investmentMocByInvestmentYear}
        >
          <Header text="MoC by Investment Year" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="investmentMocByInvestmentYear"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.investmentMoCBucketsByInvestmentYear}
        >
          <Header
            text="Investment Performance Buckets by Investment Year"
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="investmentMoCBucketsByInvestmentYear"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.investmentMocBySector}
        >
          <Header text="MoC by Sector" size="xl" displayIcon />
          <BenchmarkingPlotGQL
            plotID="investmentMocBySector"
            filters={chosenFilters}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withScrollingToComponent(FundPortfolio)
);
