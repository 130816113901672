import gql from "graphql-tag";
import { formatMultiple } from "../../../utils/formatNumbers";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import {
  PRIMARY_INDIGO,
  PRIMARY_PINK,
  PRIMARY_YELLOW,
} from "../../../utils/colors";

export const investmentMocBySector: PlotSpec = {
  query: gql`
    query investmentMocBySector(
      $filters: investmentsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      investments(
        dimensions: ["investmentSectorGroup"]
        filters: $filters
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        investmentSectorGroup
        mocQ1: mocQuantile(q: 0.75)
        mocQ2: mocQuantile(q: 0.50)
        mocQ3: mocQuantile(q: 0.25)
        censoringInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "bar",
        x: data.investments.investmentSectorGroup,
        y: data.investments.mocQ1,
        hovertext: data.investments.mocQ1.map(formatMultiple),
        hoverinfo: "x+text+name",
        name: "MoC Q1",
        marker: {
          color: PRIMARY_PINK,
        },
      },
      {
        type: "bar",
        x: data.investments.investmentSectorGroup,
        y: data.investments.mocQ2,
        hovertext: data.investments.mocQ2.map(formatMultiple),
        hoverinfo: "x+text+name",
        name: "MoC Q2",
        marker: {
          color: PRIMARY_INDIGO,
        },
      },
      {
        type: "bar",
        x: data.investments.investmentSectorGroup,
        y: data.investments.mocQ3,
        hovertext: data.investments.mocQ3.map(formatMultiple),
        hoverinfo: "x+text+name",
        name: "MoC Q3",
        marker: {
          color: PRIMARY_YELLOW,
        },
      },
    ],
    layout: {
      ...getDefaultLayout(),
      margin: { t: 20, r: 20, b: 120, l: 30 },
    },
  }),
  censoringChecker: (data) => data.investments.censoringInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Sector", "MoC Q1", "MoC Q2", "MoC Q3"],
    data: [
      data.investments.investmentSectorGroup,
      data.investments.mocQ1.map(formatMultiple),
      data.investments.mocQ2.map(formatMultiple),
      data.investments.mocQ3.map(formatMultiple),
    ],
  }),
};
