export const PRIMARY_PINK = "rgba(255,90,251,1)";
export const PRIMARY_PINK_10 = "rgba(255,197,253,1)";
export const PRIMARY_PINK_20 = "rgba(255,172,252,1)";
export const PRIMARY_PINK_30 = "rgba(255,147,251,1)";
export const PRIMARY_PINK_40 = "rgba(255,119,251,1)";

export const PRIMARY_INDIGO = "rgba(48,38,128,1)";
export const PRIMARY_INDIGO_10 = "rgba(157,147,192,1)";
export const PRIMARY_INDIGO_20 = "rgba(125,113,171,1)";
export const PRIMARY_INDIGO_30 = "rgba(105,92,157,1)";
export const PRIMARY_INDIGO_40 = "rgba(76,65,138,1)";
export const PRIMARY_INDIGO_TRANS = "rgba(48,38,128,0.5)";

export const PRIMARY_YELLOW = "rgba(255,213,0,1)";
export const PRIMARY_YELLOW_10 = "rgba(255,255,77,1)";

export const SECONDARY_PURPLE = "rgba(173,26,149,1)";
export const SECONDARY_ORANGE = "rgba(255,153,0,1)";
export const SECONDARY_ORANGE_TRANS = "rgba(255,153,0,0.3)";

export const HEATMAP_COLORS: Array<[number, string]> = [
  [0, "rgba(48,38,128,.1)"],
  [0.083, "rgba(48,38,128,.2)"],
  [0.166, "rgba(48,38,128,.3)"],
  [0.25, "rgba(48,38,128,.4)"],
  [0.333, "rgba(48,38,128,.5)"],
  [0.416, "rgba(48,38,128,.6)"],
  [0.5, "rgba(48,38,128,.65)"],
  [0.583, "rgba(48,38,128,.7"],
  [0.666, "rgba(48,38,128,.75)"],
  [0.75, "rgba(48,38,128,.8)"],
  [0.833, "rgba(48,38,128,.85)"],
  [0.916, "rgba(48,38,128,.9)"],
  [1, "rgba(48,38,128,1)"],
];
export const DUAL_HEATMAP_COLORS: Array<[number, string]> = [
  [0, "#db00fd"],
  [0.08333333333333333, "#d302f7"],
  [0.16666666666666666, "#ba05e5"],
  [0.25, "#ab07da"],
  [0.3333333333333333, "#970acb"],
  [0.4166666666666667, "#8d0cc4"],
  [0.5, "#850dbe"],
  [0.5833333333333334, "#7710b4"],
  [0.6666666666666666, "#6512a7"],
  [0.75, "#58149d"],
  [0.8333333333333334, "#481691"],
  [0.9166666666666666, "#40178c"],
  [1, "#2e3180"],
];

export const PIE_COLORS = [
  PRIMARY_YELLOW,
  PRIMARY_INDIGO,
  PRIMARY_PINK,
  SECONDARY_PURPLE,
  SECONDARY_ORANGE,
  PRIMARY_PINK_20,
];
