import React, { FunctionComponent, useRef, useEffect } from "react";
import classNames from "classnames";
import "./SelectItem.scss";

interface ISelectItem {
  key: number;
  value: string;
  optionSelected: boolean;
  optionKeyDown: boolean;
  handleClick: (value: string) => void;
}

type Props = ISelectItem;

export const SelectItem: FunctionComponent<Props> = (props) => {
  const itemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.optionKeyDown && itemRef && itemRef.current) {
      itemRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  });

  const itemClass = classNames("Select__item", {
    "Select__item--selected": props.optionSelected,
    "Select__item--onKeyDown": props.optionKeyDown,
  });

  return (
    <div
      ref={itemRef}
      onClick={() => props.handleClick(props.value)}
      data-title={props.value}
      className={itemClass}
      title={props.value}
    >
      <span>{props.value}</span>
    </div>
  );
};
