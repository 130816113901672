import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

import {
  INewPasswordRequired,
  ICredentials,
  IConfirmNewPassword,
  ISession,
  ICodeDeliveryDetails,
} from "./types";

interface UserResponse extends CognitoUser {
  challengeName: string;
}

const extractLoggedUser = (user: UserResponse): ISession => {
  if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
    return {
      authenticated: false,
      newPasswordRequired: true,
      passwordResetRequired: false,
      isLoginModalOpen: true,
      user,
      permissions: {
        canViewSelectedPortfolio: false,
        canViewLPATerms: false,
      },
    };
  } else {
    const us = user.getSignInUserSession() as CognitoUserSession;
    const idToken = us.getIdToken();
    const groups = new Set(idToken.payload["cognito:groups"]);
    return {
      authenticated: true,
      idToken: idToken.getJwtToken(),
      username: idToken.payload.email,
      permissions: {
        canViewSelectedPortfolio: groups.has("Investor") || groups.has("EIF"),
        canViewLPATerms: groups.has("EIF"),
      },
      newPasswordRequired: false,
      passwordResetRequired: false,
      isLoginModalOpen: false,
    };
  }
};

const extractForgottenPasswordInfo = (response: ICodeDeliveryDetails) => {
  const { CodeDeliveryDetails } = response;
  return { recoveryCodeSend: !!CodeDeliveryDetails.Destination };
};

export const login = (data: ICredentials) => {
  return Auth.signIn({ ...data }).then(extractLoggedUser);
};

export const setNewPassword = (data: INewPasswordRequired) => {
  return Auth.completeNewPassword(data.user, data.password, {}).then(
    extractLoggedUser
  );
};

export const resetUserPassword = (userName: string) => {
  return Auth.forgotPassword(userName).then(extractForgottenPasswordInfo);
};

export const submitUserNewPassword = (data: IConfirmNewPassword) => {
  return Auth.forgotPasswordSubmit(
    data.username,
    data.code,
    data.password
  ).then((response) => response);
};

export const initLogin = () => {
  return Auth.currentAuthenticatedUser({ bypassCache: false }).then(
    extractLoggedUser
  );
};

export const logout = () => {
  return Auth.signOut();
};
