import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../Icon";
import "./Button.scss";

type ButtonTypes = "submit" | "button" | "reset";

interface IButtonProps extends Partial<IStyleSize> {
  active?: boolean;
  block?: boolean;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  flat?: boolean;
  href?: string;
  icon?: string;
  nowrap?: boolean;
  onClick?: () => void;
  outline?: boolean;
  success?: boolean;
  white?: boolean;
  type?: ButtonTypes;
}

export const Button: React.FunctionComponent<IButtonProps> = ({
  active,
  block,
  children,
  className,
  danger,
  disabled,
  flat,
  href,
  icon,
  nowrap,
  onClick,
  outline,
  size,
  success,
  type = "button",
  white,
}) => {
  const btnClass = classNames(
    "Button",
    {
      "Button--outline": !!outline,
      "Button--flat": !!flat,
      "Button--danger": !!danger,
      "Button--success": !!success,
      "Button--white": !!white,
      "Button--active": !!active,
      "Button--block": !!block,
      "Button--nowrap": !!nowrap,
      "Button--disabled": !!disabled,
      "Button--icon": !!icon,
      [`Button--size-${size}`]: !!size,
    },
    className
  );

  if (href) {
    return (
      <Link className={btnClass} to={href}>
        {icon && <Icon icon={icon} />}
        <span>{children}</span>
      </Link>
    );
  }

  return (
    <button
      onClick={onClick}
      className={btnClass}
      disabled={disabled}
      type={type}
    >
      {icon && <Icon icon={icon} />}
      <span>{children}</span>
    </button>
  );
};
