import React, { Component } from "react";
import { connect } from "react-redux";

import { selectors } from "../../../../data/filters";
import { RootState } from "../../../../store";
import { BenchmarkingPlotGQL } from "../../../../components/BenchmarkingPlot";
import { Header } from "../../../../components/Header";
import withScrollingToComponent, {
  IComponentReferences,
} from "../../../../utils/HOC/withScrollingToComponent";
import { PlotTabInnerProps } from "../types";

function mapStateToProps(state: RootState) {
  return selectors(state);
}

class FundEconomy extends Component<PlotTabInnerProps> {
  private readonly componentReferences: IComponentReferences;

  constructor(props: PlotTabInnerProps) {
    super(props);
    this.componentReferences = {
      fundIPMgmtFeeByVintage: React.createRef(),
      fundPostIPMgmtFeeByVintage: React.createRef(),
      fundCarryByVintage: React.createRef(),
      fundHurdleByVintage: React.createRef(),
    };

    props.setReferences(this.componentReferences);
  }

  render() {
    const { chosenFilters } = this.props;

    return (
      <div className="Explorer__graphs-container">
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundIPMgmtFeeByVintage}
        >
          <Header
            text="Number of Funds by IP Management Fee Bucket and Vintage"
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="fundIPMgmtFeeByVintage"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundPostIPMgmtFeeByVintage}
        >
          <Header
            text="Number of Funds by Post IP Management Fee Bucket and Vintage"
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="fundPostIPMgmtFeeByVintage"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundCarryByVintage}
        >
          <Header
            text="Number of Funds by Carry Bucket and Vintage"
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="fundCarryByVintage"
            filters={chosenFilters}
          />
        </div>
        <div
          className="Explorer__graph"
          ref={this.componentReferences.fundHurdleByVintage}
        >
          <Header
            text="Number of Funds by Hurdle Rate Bucket and Vintage"
            size="xl"
            displayIcon
          />
          <BenchmarkingPlotGQL
            plotID="fundHurdleByVintage"
            filters={chosenFilters}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withScrollingToComponent(FundEconomy));
