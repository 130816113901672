import classNames from "classnames";
import React, { FunctionComponent, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { Container } from "../Grid";
import { Icon } from "../Icon";

import "./NavBar.scss";
import { PlotTabConnectedAndScrolling } from "../../views/Explorer/components/types";

interface ComponentProps {
  routes: Array<INavBarRoute<PlotTabConnectedAndScrolling>>;
  activeTab: string;
}

const NavBar: FunctionComponent<ComponentProps> = ({ routes, activeTab }) => {
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);

  return (
    <div className="NavBar">
      <Container className="NavBar__container">
        <div className="NavBar__wrapper">
          <div
            className={classNames("NavBar__toggle", {
              "NavBar__toggle--expanded": showResponsiveMenu,
            })}
            onClick={() => setShowResponsiveMenu(!showResponsiveMenu)}
          >
            <span className="NavBar__toggle--title">Funds Menu</span>
            <span
              className={classNames("chevron", { bottom: !showResponsiveMenu })}
            />
          </div>
          {routes.map((route) => {
            return (
              <div
                key={route.name}
                className={classNames(
                  { NavBar__dropdown: route.subMenu },
                  { "NavBar__item--right": route.name === "glossary" },
                  { "NavBar__item--spacer": route.name === "spacer" }
                )}
              >
                <NavLink
                  className={classNames(
                    "NavBar__item",
                    { "NavBar__item--spacer": route.name === "spacer" },
                    { "NavBar__item--visible": !showResponsiveMenu }
                  )}
                  to={route.path}
                >
                  {route.icon && (
                    <Icon className="NavBar__icon" icon={route.icon} />
                  )}
                  <span>{route.label}</span>
                </NavLink>
                <div
                  className={classNames("NavBar__dropdown-content", {
                    "NavBar__dropdown-content--visible":
                      route.name === activeTab && showResponsiveMenu,
                  })}
                  onClick={() => setShowResponsiveMenu(!showResponsiveMenu)}
                >
                  {route.subMenu &&
                    Object.keys(route.subMenu).map((subMenuItem, index) => {
                      return (
                        <Link
                          className={classNames(
                            "NavBar__item",
                            "NavBar__dropdown-item"
                          )}
                          key={index}
                          to={`${route.path}#${subMenuItem}`}
                        >
                          {route.subMenu && route.subMenu[subMenuItem]}
                        </Link>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default NavBar;
