import {
  createActionCreator,
  createPromiseActionCreator,
  failureActionHandler,
  startedActionHandler,
  IReduxAsyncState,
  SimpleAction,
} from "../../utils/redux";

import {
  ISession,
  initLogin,
  login,
  logout,
  resetUserPassword,
  submitUserNewPassword,
  setNewPassword,
  ILoginModalState,
} from ".";
import { Reducer } from "redux";

export interface SessionState extends IReduxAsyncState<ISession> {}
type SessionAction = SimpleAction<ISession>;

export const sessionDestroy = createActionCreator("SESSION_DESTROY");
export const changeTab = createActionCreator("CHANGE_LOGIN_TAB");
export const toggleLoginModal = createActionCreator("TOGGLE_LOGIN_MODAL");

export const createSession = createPromiseActionCreator(
  "SESSION_REQUEST",
  login
);
export const initSession = createPromiseActionCreator(
  "SESSION_INIT",
  initLogin
);
export const resetPasswordRequest = createPromiseActionCreator(
  "RESET_PASSWORD_REQUEST",
  resetUserPassword
);
export const resetPasswordSubmit = createPromiseActionCreator(
  "RESET_PASSWORD_SUBMIT",
  submitUserNewPassword
);
export const newPasswordSubmit = createPromiseActionCreator(
  "NEW_PASSWORD_REQUIRED_SUBMIT",
  setNewPassword
);

const defaultState: SessionState = {
  payload: {
    authenticated: false,
    idToken: "",
    username: "",
    isLoginModalOpen: false,
    permissions: {
      canViewSelectedPortfolio: false,
      canViewLPATerms: false,
    },
    newPasswordRequired: false,
    user: undefined,
    passwordResetRequired: false,
    recoveryCodeSend: false,
  },
  working: false,
};

const successUserLoginHandler = (
  state: SessionState,
  action: SessionAction
): SessionState => {
  const payload = action.payload as ISession;
  return {
    payload: {
      authenticated: payload.authenticated,
      idToken: payload.idToken,
      username: payload.username,
      isLoginModalOpen: payload.isLoginModalOpen,
      permissions: payload.permissions,
      newPasswordRequired: payload.newPasswordRequired,
      // Used only for NEW_PASSWORD_REQUIRED challenge
      user: payload.user,
      passwordResetRequired: false,
      recoveryCodeSend: false,
    },
    working: false,
  };
};

const successResetPasswordHandler = (
  state: SessionState,
  action: SessionAction
): SessionState => {
  const payload = action.payload as ISession;
  return {
    payload: {
      ...defaultState.payload,
      isLoginModalOpen: state.payload.isLoginModalOpen,
      recoveryCodeSend: !!payload.recoveryCodeSend,
    },
    working: false,
  };
};

const successSubmitNewPasswordHandler = (
  state: SessionState,
  action: SessionAction
): SessionState => {
  return {
    ...defaultState,
    payload: {
      ...defaultState.payload,
      isLoginModalOpen: state.payload.isLoginModalOpen,
    },
  };
};

const sessionDestroyHandler = (
  state: SessionState,
  action: SessionAction
): SessionState => {
  logout();
  return {
    ...defaultState,
    payload: {
      ...defaultState.payload,
      isLoginModalOpen: state.payload.isLoginModalOpen,
    },
  };
};

const sessionChangeLoginTabHandler = (
  state: SessionState,
  action: SessionAction
): SessionState => {
  return {
    ...defaultState,
    payload: {
      ...defaultState.payload,
      isLoginModalOpen: state.payload.isLoginModalOpen,
    },
  };
};

const toggleLoginModalHandler = (
  state: SessionState,
  action: SimpleAction<ILoginModalState>
): SessionState => {
  return {
    ...state,
    payload: {
      ...state.payload,
      isLoginModalOpen: !state.payload.isLoginModalOpen,
    },
  } as SessionState;
};

export const sessionReducer: Reducer<SessionState, SessionAction> = (
  state,
  action
) => {
  const s = state || defaultState;
  switch (action.type) {
    case createSession.actionStartedType:
    case initSession.actionStartedType:
    case resetPasswordRequest.actionStartedType:
    case resetPasswordSubmit.actionStartedType:
    case newPasswordSubmit.actionStartedType:
      return startedActionHandler(s, action);
    case initSession.actionFailureType:
    case resetPasswordRequest.actionFailureType:
    case resetPasswordSubmit.actionFailureType:
    case newPasswordSubmit.actionFailureType:
      return failureActionHandler(s, action);
    case createSession.actionFailureType:
      if (
        action.error &&
        action.error.name === "PasswordResetRequiredException"
      ) {
        return {
          ...s,
          payload: {
            ...s.payload,
            passwordResetRequired: true,
          },
          error: undefined,
          working: false,
        };
      } else {
        return Object.assign({}, state, {
          error: action.error,
          working: false,
        });
      }
    case createSession.actionSuccessType:
    case initSession.actionSuccessType:
    case newPasswordSubmit.actionSuccessType:
      return successUserLoginHandler(s, action);
    case resetPasswordRequest.actionSuccessType:
      return successResetPasswordHandler(s, action);
    case resetPasswordSubmit.actionSuccessType:
      return successSubmitNewPasswordHandler(s, action);
    case sessionDestroy.actionType:
      return sessionDestroyHandler(s, action);
    case changeTab.actionType:
      return sessionChangeLoginTabHandler(s, action);
    case toggleLoginModal.actionType:
      return toggleLoginModalHandler(s, action);
    default:
      return s;
  }
};
