import gql from "graphql-tag";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import { PIE_COLORS } from "../../../utils/colors";

const labels = ["MoC < 0.1", "MoC < 1", "MoC < 2", "MoC < 5", "MoC >= 5"];

export const investmentMoCBucketsByInvestmentYear: PlotSpec = {
  query: gql`
    query investmentMoCBucketsByInvestmentYear(
      $filters: investmentsFilter
      $selectedPortfolioOnly: Boolean
    ) {
      investments(
        dimensions: ["investmentYear"]
        filters: $filters
        sorted: true
        removeCensored: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        investmentYear
        b0: numInvestmentsbyMoc(to: 0.1)
        b1: numInvestmentsbyMoc(from: 0.1, to: 1.0)
        b2: numInvestmentsbyMoc(from: 1.0, to: 2.0)
        b3: numInvestmentsbyMoc(from: 2.0, to: 5.0)
        b4: numInvestmentsbyMoc(from: 5.0)
        censoringInfo {
          hasCensoredData
        }
      }
    }
  `,
  plotGenerator: (data) => {
    // For this stacked area chart, we need to calculate the cum sum manually for each year
    let prevVal = Array<number>(data.investments.investmentYear.length).fill(0);
    const plotColors = PIE_COLORS;
    const traces = labels.map((lbl, lblIdx) => {
      const val = data.investments[`b${lblIdx}`].map(
        (elt: number, idx: number) => elt + prevVal[idx]
      );
      prevVal = val;

      // @ts-ignore
      return {
        type: "scatter",
        x: data.investments.investmentYear,
        y: val,
        fill: lblIdx === 0 ? "tozeroy" : "tonexty",
        hovertext: data.investments[`b${lblIdx}`],
        hoverinfo: "x+name+text",
        mode: "lines",
        name: lbl,
        marker: {
          color: plotColors[lblIdx],
        },
      } as Plotly.Data;
    });

    return {
      data: traces,
      layout: {
        ...getDefaultLayout("year"),
        legend: {
          ...getDefaultLayout("year").legend,
          traceorder: "normal",
        },
      },
    };
  },
  censoringChecker: (data) => data.investments.censoringInfo.hasCensoredData,
  dataTable: (data) => ({
    headers: ["Year"].concat(labels),
    data: [
      data.investments.investmentYear,
      data.investments["b0"],
      data.investments["b1"],
      data.investments["b2"],
      data.investments["b3"],
      data.investments["b4"],
    ],
  }),
};
