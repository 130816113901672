import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import iziToast from "izitoast";

import App from "./App";

import { store } from "./store";

import { register } from "./serviceWorker";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root") as HTMLElement
);

const onServiceWorkerUpdate = () => {
  console.info(
    "Welcome back! We have been busy updating _the bench_ since the last time you visited. Please close and reopen this tab, or press CTRL + F5, to view the latest version."
  );

  iziToast.info({
    theme: "dark",
    color: "rgba(48, 38, 128, 1)",
    title: "Welcome Back!",
    message:
      "We have been busy updating <em>The bench</em> since the last time you visited. Please close and reopen this tab, or press CTRL + F5, to view the latest version.",
    position: "bottomLeft",
    progressBarColor: "rgba(255, 213, 0, 1)",
    timeout: 15000, //ms
  });
};

// Register service worker
register({ onUpdate: onServiceWorkerUpdate });
