import React from "react";
import { Col, Container, Row } from "../../../../components/Grid";
import "./Glossary.scss";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";

const GET_DATASET_DATE = gql`
  query datasetInfo {
    funds(dimensions: []) {
      numFunds
      datasetInfo {
        extractionDate
        referenceDate
      }
    }
    investments(dimensions: []) {
      numInvestments
      datasetInfo {
        extractionDate
        referenceDate
      }
    }
  }
`;

// Replace hyphens with non-breaking variant
const doNotBreakMyHyphens = (s: any) => (s as string).replace(/-/g, "‑");

const Glossary = () => {
  const { loading, error, data } = useQuery(GET_DATASET_DATE);

  const loadingOrError = loading
    ? "(loading...)"
    : error
    ? "(error!)"
    : undefined;

  return (
    <Container className="Glossary">
      <Row>
        <Col size={{ sm: 12, md: 8, lg: 8, xl: 8 }}>
          <h3 className="Glossary__header  ">Questions and Answers</h3>

          <h4 className="text--bold --text-color-primary">
            When was the data last updated?
          </h4>
          <ul>
            <li>
              Funds data covering {loadingOrError ?? data.funds.numFunds} funds
              was last extracted on{" "}
              {loadingOrError ??
                doNotBreakMyHyphens(data.funds.datasetInfo.extractionDate)}{" "}
              and performance data is as of{" "}
              <strong>
                {loadingOrError ??
                  doNotBreakMyHyphens(data.funds.datasetInfo.referenceDate)}
                .
              </strong>
            </li>
            <li>
              Investments data covering{" "}
              {loadingOrError ?? data.investments.numInvestments} investments
              was last extracted on{" "}
              {loadingOrError ??
                doNotBreakMyHyphens(
                  data.investments.datasetInfo.extractionDate
                )}{" "}
              and performance data is as of{" "}
              <strong>
                {loadingOrError ??
                  doNotBreakMyHyphens(
                    data.investments.datasetInfo.referenceDate
                  )}
                .
              </strong>
            </li>
          </ul>
          <p>We plan to update the data on a quarterly basis.</p>
          <br />
          <h4 className="text--bold --text-color-primary">
            What currencies are the amounts in?
          </h4>
          <p>
            All amounts are in Euro, and converted at transaction date if
            applicable.
          </p>
          <br />
          <h4 className="text--bold --text-color-primary">
            Why do I get a warning about data having been omitted?
          </h4>
          <p>
            To provide confidentiality to our counterparties, the platform
            provides non-public data, like fund multiples and fees, only when at
            least five funds are included in the current filtered set.
          </p>
          <br />
          <h4 className="text--bold --text-color-primary">
            I find some of your figures hard to believe...
          </h4>
          <p>
            Even though we took utmost care of checking the correctness of the
            data, sometimes our internal sources are not entirely correct or up
            to date. The dynamic nature and amount of data on <em>the bench</em>{" "}
            also makes exhaustive checking of every possible chart unfeasible.
            Please{" "}
            <a href="mailto:thebench@eif.org?subject=Data Quality Inquiry">
              let us know
            </a>{" "}
            if you spot something that seems off, and we also kindly ask you to
            verify figures with us if you are going to use any of the data
            points of <em>the bench</em> in publicly available media, such as
            articles or social media posts.
          </p>
          <br />
          <h4 className="text--bold --text-color-primary">
            Which funds are included?
          </h4>
          <p>
            We include most signed VC, PE and Hybrid funds. We exclude certain
            mandate-driven fund investments and special cases that would not
            typically be considered part of one of the aforementioned
            categories.
          </p>
          <br />
          <h4 className="text--bold --text-color-primary">
            Which portfolio companies are included?
          </h4>
          <p>
            We exclude investments with less than 50.000€ of invested amount to
            avoid outliers artificially inflating average multiples. Also here,
            data is only shown when at least five investment are included in a
            given aggregation. Note that a given company might be invested in by
            multiple funds, in which case we would include it twice.
          </p>
          <br />
          <h4 className="text--bold --text-color-primary">
            The portfolio section shows a significant amount of companies
            located outside of Europe receiving investments, even though the
            EIF's focus is Europe. Why is that?
          </h4>
          <p>
            The EIF typically requires that two thirds of the fund’s invested
            amounts are allocated to companies that – at the time of the initial
            investment – are based or active in Europe. Thus, one third of the
            portfolio can be composed of non-European companies, adding for
            example geographical diversification benefits to the portfolio where
            justified by the fund’s investment strategy. Moreover, many European
            companies relocate or expand to non-European markets after the
            initial investment as part of the growth of their operations.
          </p>
          <h3 className="Glossary__header  ">Glossary</h3>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">Vintage Year</h4>
            <p>
              Year of first drawdown. Note that this does not always match the
              year of EIF signature, as reported for instance in the EIF's{" "}
              <a href="https://www.eif.org/news_centre/publications/all/index.htm?category=annual-report">
                Annual Report.
              </a>
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              EIF Commitment
            </h4>
            <p>
              EUR amounts (converted at transaction date) pledged by EIF,
              including funds from mandates.
            </p>
            <p>
              A complete list can be found in the Annual Report, section Signed
              Transactions and Mandates.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Selected Portfolio
            </h4>
            <p>
              This is a subset of the entire EIF fund portfolio showing funds
              with vintage year ≥ 2004 which achieved a high internal grade. The
              option to show only this portfolio might only be provided to
              certain users. Contact us if you would like to gain access.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Investor Group
            </h4>
            <p>
              We have grouped investors in the following indicative categories:
            </p>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  EIF/EIB + Mandates:{" "}
                </span>
                EIF and EIB mandates or own resources. Mandates are programmes
                sponsored by EU, national or regional entities providing funding
                to promote policy objectives. They include, among many others:
                <ul>
                  <li>
                    <a href="https://www.eif.org/what_we_do/resources/rcr/index.htm">
                      RCR
                    </a>
                  </li>
                  <li>
                    <a href="https://www.eif.org/what_we_do/resources/erp/index.htm">
                      ERP
                    </a>
                  </li>
                  <li>
                    <a href="https://www.eif.org/what_we_do/equity/Case_studies/index.htm">
                      CIP GIF
                    </a>
                  </li>
                  <li>
                    <a href="https://www.eif.org/what_we_do/equity/single_eu_equity_instrument/innovfin-equity/index.htm">
                      InnovFin IFE
                    </a>
                  </li>
                  <li>
                    <a href="https://www.eif.org/what_we_do/efsi/">EFSI</a>
                  </li>
                </ul>
              </li>
              <li>
                <span className="text--bold --text-color-secondary">
                  Public Investors:{" "}
                </span>
                Includes government agencies, banks investing public resources,
                funds of funds (at least partially funded by states).
              </li>
              <li>
                <span className="text--bold --text-color-secondary">
                  Institutional Investors:{" "}
                </span>
                Includes banks, private endowments, foundations, insurance
                companies, funds, funds of funds, academic institutions.
              </li>
              <li>
                <span className="text--bold --text-color-secondary">
                  Other:{" "}
                </span>
                All other types of investors.
              </li>
            </ul>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">TVPI</h4>
            <p>
              <em>Total value to paid in capital</em> - Reported NAV of holdings
              plus repayment, dividends or other income (including secondary
              sale proceeds), over total capital drawn. Excludes equalisation
              fees or revenue. As of the latest NAV date. Individual fund values
              are averaged using the EIF commitment as weight.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">DPI</h4>
            <p>
              <em>Distributions to paid in capital</em> - Repayment, dividends
              or other income (including secondary sale proceeds), over total
              capital drawn. Excludes equalisation fees or income. As of the
              latest NAV date. Individual fund values are averaged using the EIF
              commitment as weight.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Performance Quartiles (Q1, Q2, Q3)
            </h4>
            <p>
              The indicated values correspond to the 75%, 50% and 25%
              percentiles of the underlying performance measure. Equivalently,{" "}
              <em>Q1</em> is the cutpoint between the highest and second highest
              performance quartile, while <em>Q2</em> corresponds to the median
              performance.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Catalytic Effect
            </h4>
            <p>
              This is a common, if optimistic, assessment of the EIF's impact,
              calculated as ratio of total fund commitments over EIF commitment.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Invested Amount
            </h4>
            <p>
              This is the total EUR amount - if necessary, converted at
              transaction date - invested, not only the theoretical EIF share.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">MoC</h4>
            <p>
              <em>Multiple over Cost</em> - (Latest Valuation of EIF stake +
              Proceeds) / Invested Amount, as of the latest valuation date. All
              amounts for this calculation are in the currency of the
              investment. Individual investment values are averaged using the
              invested amount as weight.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">Realised MoC</h4>
            <p>
              <em>Realized Multiple over Cost</em> - Proceeds / Invested Amount,
              as of the latest valuation date. All amounts for this calculation
              are in the currency of the investment. Individual investment
              values are averaged using the invested amount as weight.
            </p>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              State of Business
            </h4>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Positive Current State of Business:{" "}
                </span>{" "}
                Net Positive Current State of Business reflects the percentage
                of respondents rating the current state of business as "Good /
                Very good" minus the percentage of respondents rating the
                current state of business as "Bad / Very bad".
              </li>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Expected Improvement in Future State of Business:{" "}
                </span>{" "}
                Net Expected Improvement in Future State of Business reflects
                the percentage of respondents expecting the future state of
                business as "Slightly / Strongly improve" minus the percentage
                of respondents expecting the future state of business as
                "Slightly / Strongly deteriorate".
              </li>
            </ul>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Fundraising Environment
            </h4>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Positive Current Fundraising Environment:{" "}
                </span>{" "}
                Net Positive Current Fundraising Environment reflects the
                percentage of respondents rating the current fundraising
                environment as "Good / Very good" minus the percentage of
                respondents rating the current fundraising environment as "Bad /
                Very bad".
              </li>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Expected Improvement in Future Fundraising Environment:{" "}
                </span>{" "}
                Net Expected Improvement in Future Fundraising Environment
                reflects the percentage of respondents expecting the future
                fundraising environment as "Slightly / Strongly improve" minus
                the percentage of respondents expecting the future fundraising
                environment as "Slightly / Strongly deteriorate".
              </li>
            </ul>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Easiness to Find Co-Investors
            </h4>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Positive Current Easiness to Find Co-Investors:{" "}
                </span>{" "}
                Net Positive Current Easiness to Find Co-Investors reflects the
                percentage of respondents rating the current easiness to find
                co-Investors as "Easy / Very easy" minus the percentage of
                respondents rating the current easiness to find co-Investors as
                "Difficult / Very difficult".
              </li>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Expected Improvement in Future Easiness to Find
                  Co-Investors:{" "}
                </span>{" "}
                Net Expected Improvement in Future Easiness to Find Co-Investors
                reflects the percentage of respondents expecting the future
                easiness to find co-Investors as "Slightly / Much easier" minus
                the percentage of respondents expecting the future easiness to
                find co-Investors as "Slightly / Much more difficult".
              </li>
            </ul>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Investment Proposals
            </h4>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Expected Improvement in Future Investment Proposals:{" "}
                </span>{" "}
                Net Expected Improvement in Future Investment Proposals reflects
                the percentage of respondents expecting the future investment
                proposals as "Slightly / Strongly increase" minus the percentage
                of respondents expecting the future investment proposals as
                "Slightly / Strongly decrease".
              </li>
            </ul>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              New Investments
            </h4>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Expected Improvement in Future New Investments:{" "}
                </span>{" "}
                Net Expected Improvement in Future New Investments reflects the
                percentage of respondents expecting the future new investments
                as "Slightly / Strongly increase" minus the percentage of
                respondents expecting the future new investments as "Slightly /
                Strongly decrease".
              </li>
            </ul>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Portfolio Development
            </h4>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Positive Current Portfolio Development:{" "}
                </span>{" "}
                Net Positive Current State of Portfolio Development reflects the
                percentage of respondents rating the current state of portfolio
                development as "Slightly / Significantly above expectations"
                minus the percentage of respondents rating the current state of
                portfolio development as "Slightly / Significantly below
                expectations".
              </li>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Expected Improvement in Future Portfolio Development:{" "}
                </span>{" "}
                Net Expected Improvement in Future State of Portfolio
                Development reflects the percentage of respondents expecting the
                future state of portfolio development as "Slightly /
                Significantly improve" minus the percentage of respondents
                expecting the future state of portfolio development as "Slightly
                / Significantly deteriorate".
              </li>
            </ul>
          </div>
          <div className="Glossary__item">
            <h4 className="text--bold --text-color-secondary">
              Exit Opportunities
            </h4>
            <ul>
              <li>
                <span className="text--bold --text-color-secondary">
                  Net Expected Improvement in Future Exit Opportunities:{" "}
                </span>{" "}
                Net Expected Improvement in Future Exit Opportunities reflects
                the percentage of respondents expecting the future exit
                opportunities as "Slightly / Strongly improve" minus the
                percentage of respondents expecting the future exit
                opportunities as "Slightly / Strongly deteriorate".
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Glossary;
