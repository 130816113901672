import {
  fundCommitByVintage,
  fundCommitByCountry,
  fundInvestorGroups,
  fundIRRBySector,
  fundIRRByVintage,
  fundTVPIByVintage,
  fundTVPIBySector,
  fundDPIByVintage,
  fundDPIBySector,
  fundCarryByVintage,
  fundHurdleByVintage,
  fundIPMgmtFeeByVintage,
  fundPostIPMgmtFeeByVintage,
} from "./funds";
import {
  investmentByCompanyLocation,
  investmentMocByInvestmentYear,
  investmentMoCBucketsByInvestmentYear,
  investmentMocBySector,
} from "./investment";
import {
  vcSurveyCurrent,
  vcSurveyFuture,
  vcSurveyByCountryGroup,
  vcSurveyByStageFocus,
  vcSurveyBySector,
} from "./vcsurvey";
import { VCSurveyTopic } from "../filters";

const allPlots = {
  fundCommitByVintage,
  fundCommitByCountry,
  fundInvestorGroups,
  fundIRRByVintage,
  fundIRRBySector,
  fundTVPIByVintage,
  fundTVPIBySector,
  fundDPIByVintage,
  fundDPIBySector,
  fundCarryByVintage,
  fundHurdleByVintage,
  fundIPMgmtFeeByVintage,
  fundPostIPMgmtFeeByVintage,
  investmentByCompanyLocation,
  investmentMocByInvestmentYear,
  investmentMoCBucketsByInvestmentYear,
  investmentMocBySector,
  vcSurveyCurrent,
  vcSurveyFuture,
  vcSurveyByCountryGroup,
  vcSurveyBySector,
  vcSurveyByStageFocus,
};

export type PlotID = keyof typeof allPlots;

export const plots = (
  plotID: PlotID,
  vcSurveyTopic?: VCSurveyTopic
): PlotSpec => {
  return vcSurveyTopic
    ? (allPlots[plotID] as any)(vcSurveyTopic)
    : allPlots[plotID];
};
