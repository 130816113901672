import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { changeTab, sessionSelector } from "../../data/session";

import { LoginForm } from "./components/LoginForm";
import { ForgotPassword } from "./components/ForgotPassword";

import "./Login.scss";
import { RootState } from "../../store";

interface ComponentProps {
  onSubmit: (redirect: boolean) => void;
}

interface MapStateProps {
  passwordResetRequired: boolean;
}

const mapStateToProps = (state: RootState) => {
  const { passwordResetRequired } = sessionSelector(state);
  return {
    passwordResetRequired,
  };
};

interface MapDispatchProps {
  changeTab: () => void;
}

const mapDispatchToProps = {
  changeTab,
};

type Props = ComponentProps & MapStateProps & MapDispatchProps;

const Login = (props: Props) => {
  const [forgotPassword, setForgotPassword] = useState(false);

  useEffect(() => {
    // Switch to forgot password when password reset was required by admin
    if (props.passwordResetRequired) {
      setForgotPassword(true);
    }
  }, [props.passwordResetRequired]);

  const forgotPasswordHandler = () => {
    props.changeTab();
    setForgotPassword(true);
  };

  const signInHandler = () => {
    props.changeTab();
    setForgotPassword(false);
  };

  return (
    <div className="LoginForm">
      {forgotPassword ? (
        <>
          <ForgotPassword backToLogin={signInHandler} />
          <p className="LoginForm--item" onClick={signInHandler}>
            Sign In
          </p>
        </>
      ) : (
        <>
          <LoginForm goToChangePassword={forgotPasswordHandler} />
          <p className="LoginForm--item" onClick={forgotPasswordHandler}>
            Forgot your password?
          </p>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
