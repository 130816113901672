import React, { FormEvent } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { CognitoUser } from "amazon-cognito-identity-js";

import { RootState } from "../../../../store";
import {
  ICredentials,
  INewPasswordRequired,
  createSession,
  newPasswordSubmit,
  sessionSelector,
  ISession,
} from "../../../../data/session";
import { requireTermsOfUseAcceptance } from "../../../../data/disclaimers";
import { checkValidity, defaultInputState } from "../../../../utils/formData";

import { Button } from "../../../Button";
import {
  ICredentialsValidation,
  Group,
  Input,
  IInputValidation,
} from "../../../Form";
import Spinner from "../../../Spinner";

interface MapDispatchProps {
  createSession: (data: ICredentials) => Promise<ISession>;
  requireTermsOfUseAcceptance: () => void;
  newPasswordSubmit: (data: INewPasswordRequired) => Promise<ISession>;
}
const actionCreators = {
  createSession,
  newPasswordSubmit,
  requireTermsOfUseAcceptance,
};

export interface IError extends Error {
  code: string;
}

interface MapStateProps {
  working: boolean;
  error?: IError;
  newPasswordRequired: boolean;
  user?: CognitoUser;
  authenticated: boolean;
}
const mapStateToProps = (state: RootState) => {
  const {
    working,
    error,
    newPasswordRequired,
    user,
    authenticated,
  } = sessionSelector(state);
  return {
    authenticated,
    working,
    error,
    newPasswordRequired,
    user,
  };
};

interface State extends ICredentialsValidation {
  newPassword: IInputValidation;
}

interface ComponentProps {
  goToChangePassword: () => void;
}

type Props = ComponentProps & MapStateProps & MapDispatchProps;

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      username: {
        ...defaultInputState,
        validation: {
          isEmail: true,
          required: true,
        },
      },
      password: { ...defaultInputState },
      newPassword: { ...defaultInputState },
    };
  }

  handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const { username, password, newPassword } = this.state;
    const {
      newPasswordRequired,
      newPasswordSubmit,
      createSession,
      user,
      requireTermsOfUseAcceptance,
    } = this.props;
    if (user && newPasswordRequired) {
      newPasswordSubmit({ user, password: newPassword.value });
    } else {
      try {
        await createSession({
          username: username.value,
          password: password.value,
        });
        requireTermsOfUseAcceptance();
      } catch (e) {
        console.warn(`Failed to create session: ${e.message}`);
      }
    }
  };

  handleChange = (value: { [key: string]: string }) => {
    const currentField = Object.keys(value)[0];
    this.setState({
      [currentField]: {
        ...this.state[currentField],
        value: value[currentField],
        valid: checkValidity(
          value[currentField],
          this.state[currentField].validation
        ),
        touched: true,
      },
    } as State);
  };

  isLoginButtonDisabled = () => {
    const { username, password, newPassword } = this.state;

    return (
      (!this.props.newPasswordRequired &&
        (!password.valid ||
          !password.touched ||
          !username.valid ||
          !username.touched)) ||
      (this.props.newPasswordRequired &&
        (!newPassword.valid || !newPassword.touched))
    );
  };

  isInputValid = (fieldName: string) => {
    const { valid, touched } = this.state[fieldName];

    return valid || !touched;
  };

  passwordLabel = (newPasswordRequired: boolean) =>
    `${newPasswordRequired ? "New " : ""}Password`;

  render() {
    const { working, error, newPasswordRequired } = this.props;
    const classes = classNames("LoginForm", {
      "LoginForm--loading": !!working,
    });

    return (
      <form onSubmit={this.handleSubmit} method="post" className={classes}>
        <Spinner centered show={working}>
          {error && (
            <p className="LoginForm__unauthenticated">{error.message}</p>
          )}
          {newPasswordRequired ? (
            <>
              <p className="LoginForm__change_password">Set new password</p>
              <Group
                required
                label={this.passwordLabel(newPasswordRequired)}
                size="sm"
              >
                <Input
                  name="newPassword"
                  type="password"
                  autoComplete="new-password"
                  value={this.state.newPassword.value}
                  isValid={this.isInputValid("newPassword")}
                  onChange={this.handleChange}
                />
                {!this.isInputValid("newPassword") && (
                  <span className="LoginForm__error">{`${this.passwordLabel(
                    newPasswordRequired
                  )} is required`}</span>
                )}
              </Group>
            </>
          ) : (
            <>
              <Group required label="Username" size="sm">
                <Input
                  name="username"
                  autoComplete="username"
                  placeholder="Username"
                  value={this.state.username.value}
                  isValid={this.isInputValid("username")}
                  onChange={this.handleChange}
                />
                {!this.isInputValid("username") && (
                  <span className="LoginForm__error">Username is invalid</span>
                )}
              </Group>
              <Group
                required
                label={this.passwordLabel(newPasswordRequired)}
                size="sm"
              >
                <Input
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={this.state.password.value}
                  isValid={this.isInputValid("password")}
                  onChange={this.handleChange}
                />
                {!this.isInputValid("password") && (
                  <span className="LoginForm__error">{`${this.passwordLabel(
                    newPasswordRequired
                  )} is required`}</span>
                )}
              </Group>
            </>
          )}
          <Button
            size="lg"
            type="submit"
            disabled={this.isLoginButtonDisabled()}
          >
            {newPasswordRequired ? "Submit New Password" : "Login"}
          </Button>
        </Spinner>
      </form>
    );
  }
}

export default connect(mapStateToProps, actionCreators)(LoginForm);
