import React, { FunctionComponent } from "react";
import classNames from "classnames";

import "./Icon.scss";

interface ComponentProps {
  align?: string;
  size?: TStyleSize;
  icon: string;
  className?: string;
}

export const Icon: FunctionComponent<ComponentProps> = ({
  align,
  icon,
  size,
  className,
}) => {
  const classes = classNames(
    "Icon",
    {
      [`Icon--${align}`]: !!align,
      [`Icon--size-${size}`]: !!size,
      [`Icon__${icon}`]: !!icon,
    },
    className
  );

  return <i className={classes} />;
};
