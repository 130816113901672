import {
  createActionCreator,
  createPromiseActionCreator,
  startedActionHandler,
  failureActionHandler,
  IReduxAsyncState,
  SimpleAction,
} from "../../utils/redux";

import { IFiltersData, IPlotParameters } from "./types";
import { fetchFilters } from "./service";
import { Reducer } from "redux";

export const getFilters = createPromiseActionCreator(
  "FILTERS_INIT",
  fetchFilters
);
export const clearFilters = createActionCreator("FILTERS_CLEAR");
export const setActiveFilters = createActionCreator<
  IPlotParameters["selectedFilters"]
>("FILTERS_SET_ACTIVE");
export const setSurveyTopic = createActionCreator<
  IPlotParameters["vcSurveyTopic"]
>("SURVEYTOPIC_SET");
export const setSelectedPortfolioOnly = createActionCreator<
  IPlotParameters["selectedPortfolioOnly"]
>("FILTERS_SET_SELECTED_PORTFOLIO_ONLY");

export interface FiltersState extends IReduxAsyncState<IPlotParameters> {}

const defaultState: FiltersState = {
  payload: {
    filtersData: {
      FundsData: {
        vintageYear: {
          values: [],
          label: "Vintage Year",
          __typename: "FundsData",
        },
        strategy: {
          values: [],
          label: "Strategy",
          __typename: "FundsData",
        },
        sectorGroup: {
          values: [],
          label: "Sector Focus",
          __typename: "FundsData",
        },
        stageGroup: {
          values: [],
          label: "Stage Focus",
          __typename: "FundsData",
        },
        teamLocation: {
          values: [],
          label: "Team Location",
          __typename: "FundsData",
        },
      },
      InvestmentData: {
        investmentCountry: {
          values: [],
          label: "Country",
          __typename: "InvestmentData",
        },
        investmentStageGroup: {
          values: [],
          label: "Stage",
          __typename: "InvestmentData",
        },
        investmentSectorGroup: {
          values: [],
          label: "Sector",
          __typename: "InvestmentData",
        },
        investmentYear: {
          values: [],
          label: "Investment Year",
          __typename: "InvestmentData",
        },
      },
      VcSurveyData: {
        countryGroup: {
          values: [],
          label: "Country Group",
          __typename: "VcSurveyData",
        },
        sector: {
          values: [],
          label: "Sector",
          __typename: "VcSurveyData",
        },
        stageFocus: {
          values: [],
          label: "Stage Focus",
          __typename: "VcSurveyData",
        },
        surveyYear: {
          values: [],
          label: "Survey Year",
          __typename: "VcSurveyData",
        },
      },
    },
    selectedFilters: {},
    selectedPortfolioOnly: false,
    vcSurveyTopic: "State of Business",
  },
  working: false,
};

const setFiltersActionHandler = (
  state: FiltersState,
  action: SimpleAction<IPlotParameters["selectedFilters"]>
) => {
  return {
    ...state,
    payload: {
      ...state.payload,
      selectedFilters: {
        ...action.payload,
      },
    },
  };
};

const filtersSetSelectedPortfolioOnlyActionHandler = (
  state: FiltersState,
  action: SimpleAction<IPlotParameters["selectedPortfolioOnly"]>
) => {
  return typeof action.payload !== "undefined"
    ? {
        ...state,
        payload: {
          ...state.payload,
          selectedPortfolioOnly: action.payload,
        },
      }
    : state;
};

const clearFiltersActionHandler = (
  state: FiltersState,
  action: SimpleAction<IFiltersData>
) => {
  return {
    ...state,
    payload: {
      ...state.payload,
      selectedFilters: {},
      selectedPortfolioOnly: false,
    },
  };
};

const filtersInitActionSuccessHandler = (
  state: FiltersState,
  action: SimpleAction<IFiltersData>
) => {
  const { data } = action.payload as IFiltersData;
  const FundsData = (defaultState.payload || { filtersData: { FundsData: {} } })
    .filtersData.FundsData;
  const InvestmentData = (
    defaultState.payload || { filtersData: { InvestmentData: {} } }
  ).filtersData.InvestmentData;
  const VcSurveyData = (
    defaultState.payload || { filtersData: { VcSurveyData: {} } }
  ).filtersData.VcSurveyData;
  const filtersData = {
    FundsData: {},
    InvestmentData: {},
    VcSurveyData: {},
  };

  Object.entries(data).forEach(([key, filter]) => {
    if (String(filter.__typename) === String("FundsData")) {
      filtersData.FundsData[key] = FundsData[key];
      filtersData.FundsData[key].values = filter.values;
    }
    if (String(filter.__typename) === String("InvestmentData")) {
      filtersData.InvestmentData[key] = InvestmentData[key];
      filtersData.InvestmentData[key].values = filter.values;
    }
    if (String(filter.__typename) === String("VcSurveyData")) {
      filtersData.VcSurveyData[key] = VcSurveyData[key];
      filtersData.VcSurveyData[key].values = filter.values;
    }
  });

  return {
    payload: {
      ...state.payload,
      filtersData: Object.assign(
        {},
        state.payload && state.payload.filtersData,
        filtersData
      ),
    },
    working: false,
  } as FiltersState;
};

const surveyTopicSetHandler = (
  state: FiltersState,
  action: SimpleAction<IPlotParameters["vcSurveyTopic"]>
) => {
  return {
    ...state,
    payload: {
      ...state.payload,
      vcSurveyTopic: action.payload,
    },
  };
};

type FiltersAction = SimpleAction<any>;
export const filtersReducer: Reducer<FiltersState, FiltersAction> = (
  state,
  action
) => {
  const s = state || defaultState;
  switch (action.type) {
    case getFilters.actionStartedType:
      return startedActionHandler(s, action);
    case getFilters.actionSuccessType:
      return filtersInitActionSuccessHandler(s, action);
    case getFilters.actionFailureType:
      return failureActionHandler(s, action);
    case setActiveFilters.actionType:
      return setFiltersActionHandler(s, action);
    case setSelectedPortfolioOnly.actionType:
      return filtersSetSelectedPortfolioOnlyActionHandler(s, action);
    case clearFilters.actionType:
      return clearFiltersActionHandler(s, action);
    case setSurveyTopic.actionType:
      return surveyTopicSetHandler(s, action);
    default:
      return s;
  }
};
