import React, { FunctionComponent } from "react";

import "./Message.scss";

interface MessageProps {
  message: string;
  header?: string;
}

const Message: FunctionComponent<MessageProps> = ({
  message,
  header,
}: MessageProps) => (
  <div className="Message Message__error">
    {header ? <div className="Message_header">{header}</div> : null}
    <div>{message}</div>
  </div>
);

export default Message;
