import * as React from "react";
import classNames from "classnames";

export interface IColumnProperties {
  size?: IScreenSize | number;
  justify?: TStyleFlexJustify;
  align?: TStyleFlexAlign;
  grow?: TStyleFlexGrow;
  flex?: boolean;
  className?: string;
}

const Col: React.FunctionComponent<IColumnProperties> = ({
  size,
  ...props
}) => {
  const classes = classNames(
    "Col",
    [
      typeof size !== "number" &&
        size && [
          !!size.sm && { [`Col-sm-${size.sm}`]: size.sm },
          !!size.md && { [`Col-md-${size.md}`]: size.md },
          !!size.lg && { [`Col-lg-${size.lg}`]: size.lg },
          !!size.xl && { [`Col-xl-${size.xl}`]: size.xl },
        ],
    ],
    {
      [`Col-${size}`]: !!size && typeof size === "number",
      [`Col--justify-${props.justify}`]: !!props.justify,
      [`Col--align-${props.align}`]: !!props.align,
      "Col--grow": !!props.grow,
      "Col--flex": !!props.flex,
    },
    props.className
  );

  return <div className={classes}> {props.children}</div>;
};

export default Col;
