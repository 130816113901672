import React, { Component, RefObject } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { fundsRoutes } from ".";
import { Filters } from "../../components/Filters";
import { Container } from "../../components/Grid";
import { NavBar } from "../../components/NavBar";
import "./Explorer.scss";
import { tabGroupToDatasetMap } from "./router";
import { connect } from "react-redux";
import { RootState } from "../../store";
import { permissionsSelector } from "../../data/session";
import { IScrollingToProps } from "../../utils/HOC/withScrollingToComponent";

interface MapStateProps {
  canViewLPATerms: boolean;
}

const mapStateToProps = (state: RootState) => {
  const { canViewLPATerms } = permissionsSelector(state);
  return { canViewLPATerms };
};

interface ComponentProps
  extends RouteComponentProps<{ tabGroup: string; tab: string }> {}

type Props = ComponentProps & MapStateProps;

class ExplorerView extends Component<Props> {
  private readonly filtersRef: RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.filtersRef = React.createRef();
  }

  renderRoutes(
    routes: INavBarRoute<IScrollingToProps>[],
    filtersRef: RefObject<HTMLDivElement>
  ) {
    return routes.map((route) => {
      if (route.component === undefined) {
        return null;
      }

      const Component = route.component;
      return (
        <Route
          key={route.name}
          path={route.path}
          render={(props) => <Component {...props} filtersRef={filtersRef} />}
        />
      );
    });
  }

  render() {
    const { match, canViewLPATerms } = this.props;
    const activeTabGroup = match.params?.tabGroup ?? "funds";
    const activeTab = match.params?.tab ?? "fundraising";
    const dataset = tabGroupToDatasetMap[activeTabGroup];

    const filteredRoutes = fundsRoutes.filter(
      // Only certain users can retrieve the LPA terms data
      (route) => route.name !== "fund-economics" || canViewLPATerms
    );

    return (
      <div className="Explorer">
        <NavBar routes={filteredRoutes} activeTab={activeTab} />
        <Filters dataset={dataset} filtersRef={this.filtersRef} />
        <Container>
          <Switch>{this.renderRoutes(filteredRoutes, this.filtersRef)}</Switch>
        </Container>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ExplorerView);
