import gql from "graphql-tag";
import { formatEUR } from "../../../utils/formatNumbers";
import { HEATMAP_COLORS } from "../../../utils/colors";
import { getDefaultLayout } from "../../../utils/defaultLayout";

export const fundCommitByCountry: PlotSpec = {
  query: gql`
    query fundsCountry($filters: fundsFilter, $selectedPortfolioOnly: Boolean) {
      funds(
        dimensions: ["teamLocation"]
        filters: $filters
        sorted: true
        selectedPortfolioOnly: $selectedPortfolioOnly
      ) {
        teamLocation
        numFunds
        eifCommitment
      }
    }
  `,
  plotGenerator: (data) => ({
    data: [
      {
        type: "choropleth",
        locationmode: "country names",
        locations: data.funds.teamLocation,
        z: data.funds.eifCommitment,
        hovertext: data.funds.eifCommitment.map(
          (elt: number, idx: number) =>
            `${formatEUR(elt)}<br>${data.funds.numFunds[idx]} Commitment${
              data.funds.numFunds[idx] > 1 ? "s" : ""
            }`
        ),
        hoverinfo: "text+location",
        colorscale: [...HEATMAP_COLORS],
        marker: {
          line: {
            color: "rgb(180,180,180)",
            width: 0.2,
          },
        },
        zmin: 2,
        colorbar: {
          thickness: 10,
          x: 0,
          tickprefix: "€",
        },
      },
    ],
    layout: {
      ...getDefaultLayout(),
      xaxis: { range: [0, 0], autorange: true },
      yaxis: { range: [0, 0], autorange: true },
      margin: { b: 0, l: 0, r: 0, t: 0 },
      geo: {
        lataxis: { range: [0, 0], showgrid: true, gridwidth: 2 },
        lonaxis: { range: [0, 0], showgrid: true, gridwidth: 2 },
        showframe: false,
        showcoastlines: true,
        projection: {
          type: "hammer",
          scale: 3.935039464601701,
          rotation: { lat: 0, lon: 0, roll: 0 },
        },
        center: { lat: 48.9494062370519, lon: 12.381614668644168 },
        bgcolor: "rgba(255, 255, 255, .7)",
        showocean: true,
        oceancolor: "rgba(32, 145, 238, 0.05)",
      },
    },
  }),
  censoringChecker: () => false,
  dataTable: (data) => ({
    headers: ["Country", "Nr. Commitments", "EIF Commitment"],
    data: [
      data.funds.teamLocation,
      data.funds.numFunds,
      data.funds.eifCommitment.map(formatEUR),
    ],
  }),
};
