import * as React from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { IModalProps } from "./type";
import "./Modal.scss";
import Logo from "../../images/logo.svg";

const root = document.getElementById("modal-root") as HTMLElement;

class Modal extends React.Component<IModalProps> {
  onSubmit = (redirect = false) => {
    this.props.toggleModal(redirect);
  };

  render() {
    return ReactDOM.createPortal(
      <div className="Modal__overlay">
        <div className="Modal__box">
          <div className="Modal__heading">
            <img src={Logo} alt="" />
          </div>
          <div
            onClick={() => this.props.toggleModal(false)}
            className="Modal__button Modal__button--close"
          />
          {this.props.children}
        </div>
      </div>,
      root
    );
  }
}

export const ModalWithRouter = withRouter(Modal);
