import gql from "graphql-tag";

import { formatPercentWithZeroFractionDigits } from "../../../utils/formatNumbers";
import { getDefaultLayout } from "../../../utils/defaultLayout";
import { PRIMARY_PINK, PRIMARY_YELLOW } from "../../../utils/colors";
import { VCSurveyTopic } from "../../filters";

export const getGQLFields = (
  surveyTopic: VCSurveyTopic
): [string | undefined, string] => {
  switch (surveyTopic) {
    case "Fundraising Environment":
      return ["currentFundraisingEnv", "futureFundraisingEnv"];
    case "State of Business":
      return ["currentStateOfBusiness", "futureStateOfBusiness"];
    case "Portfolio Development":
      return ["currentPortfolioDev", "futurePortfolioDev"];
    case "Easiness to Find Co-Investors":
      return ["currentEaseOfSyndication", "futureEaseOfSyndication"];
    case "Investment Proposals":
      return [undefined, "futureInvestmentProposals"];
    case "New Investments":
      return [undefined, "futureNewInvestments"];
    case "Exit Opportunities":
      return [undefined, "futureExitOpportunities"];
  }
};

const filterFactory = (
  data: any
): (<T extends string | number>(xs: T[], year: number) => T[]) => (xs, year) =>
  xs.filter((_, idx) => Number(data.vcSurvey.surveyYear[idx]) === year);

const vcSurveyByDimension = (
  surveyTopic: VCSurveyTopic,
  dimensionGQLField: string,
  dimensionTitle: string
): PlotSpec => {
  const [currentGQLField, futureGQLField] = getGQLFields(surveyTopic);

  return {
    query: gql`
      query vcSurveyResultsByDimAndYear($filters: vcSurveyFilter) {
        vcSurvey(dimensions: ["${dimensionGQLField}","surveyYear"], filters: $filters, sorted: true, removeCensored: true) {
          ${
            currentGQLField
              ? `${currentGQLField} {
            labels
            responses
          }`
              : ""
          }
          ${futureGQLField} {
            labels
            responses
          }
          numRespondents
          ${dimensionGQLField}
          surveyYear
          censoringInfo {
            hasCensoredData
          }
        }
      }`,
    plotGenerator: (data) => {
      const filterYear = filterFactory(data);

      const stData = currentVsFutureData(
        data.vcSurvey,
        currentGQLField,
        futureGQLField
      );
      return {
        data: [
          {
            type: "bar",
            x: filterYear(data.vcSurvey[dimensionGQLField], 2018),
            y: filterYear(stData.netCurrent, 2018),
            name: `Net Positive Current ${surveyTopic}`,
            hovertext: filterYear(stData.netCurrent, 2018),
            hoverinfo: "x+text+name",
            legendgroup: "group",
            visible: currentGQLField !== undefined,
            marker: {
              color: PRIMARY_YELLOW,
            },
          },
          {
            type: "bar",
            x: filterYear(data.vcSurvey[dimensionGQLField], 2018),
            y: filterYear(stData.netFuture, 2018),
            name: `Net Expected Improvement in Future ${surveyTopic}`,
            hovertext: filterYear(stData.netFuture, 2018),
            hoverinfo: "x+text+name",
            legendgroup: "group2",
            marker: {
              color: PRIMARY_PINK,
            },
          },
          {
            type: "bar",
            x: filterYear(data.vcSurvey[dimensionGQLField], 2019),
            y: filterYear(stData.netCurrent, 2019),
            name: `Net Positive Current ${surveyTopic}`,
            hovertext: filterYear(stData.netCurrent, 2019),
            hoverinfo: "x+text+name",
            legendgroup: "group",
            showlegend: false,
            visible: currentGQLField !== undefined,
            xaxis: "x2",
            yaxis: "y2",
            marker: {
              color: PRIMARY_YELLOW,
            },
          },
          {
            type: "bar",
            x: filterYear(data.vcSurvey[dimensionGQLField], 2019),
            y: filterYear(stData.netFuture, 2019),
            name: `Net Expected Improvement in Future ${surveyTopic}`,
            hovertext: filterYear(stData.netFuture, 2019),
            hoverinfo: "x+text+name",
            legendgroup: "group2",
            showlegend: false,
            xaxis: "x2",
            yaxis: "y2",
            marker: {
              color: PRIMARY_PINK,
            },
          },
        ],
        layout: {
          ...getDefaultLayout(),
          margin: { b: 100, l: 50, r: 20, t: 20 },
          barmode: "group",
          xaxis: { title: "Survey Year 2018" },
          xaxis2: { title: "Survey Year 2019" },
          yaxis: {
            title: "Net Percentage of Respondents",
            titlefont: {
              size: 16,
              color: "rgb(107, 107, 107)",
            },
          },
          legend: {
            orientation: "h",
            x: 0,
            y: 1.3,
          },
          grid: { rows: 1, columns: 2, pattern: "independent" },
        },
      };
    },
    censoringChecker: (data) => data.vcSurvey.censoringInfo.hasCensoredData,
    dataTable: (data) => {
      const stData = currentVsFutureData(
        data.vcSurvey,
        currentGQLField,
        futureGQLField
      );
      const years = [2018, 2019];

      const filterYear = filterFactory(data);

      const dimValues = years
        .map((year) => filterYear(data.vcSurvey[dimensionGQLField], year))
        .reduce((acc, val) => acc.concat(val), []);
      const netCurrent = years
        .map((year) => filterYear(stData.netCurrent, year))
        .reduce((acc, val) => acc.concat(val), []);
      const netFuture = years
        .map((year) => filterYear(stData.netFuture, year))
        .reduce((acc, val) => acc.concat(val), []);
      const yearDt = years
        .map((year) => filterYear(data.vcSurvey.surveyYear, year))
        .reduce((acc, val) => acc.concat(val), []);

      return {
        headers: currentGQLField
          ? [
              "Survey Year",
              dimensionTitle,
              `Net Positive Current ${surveyTopic}`,
              `Net Expected Improvement in Future ${surveyTopic}`,
            ]
          : [
              "Survey Year",
              dimensionTitle,
              `Net Expected Improvement in Future ${surveyTopic}`,
            ],
        data: currentGQLField
          ? [yearDt, dimValues, netCurrent, netFuture]
          : [yearDt, dimValues, netFuture],
        headerCols: [0, 1],
      };
    },
  };
};

const currentVsFutureData = (
  data: any,
  currentGQLField: string | undefined,
  futureGQLField: string
) => {
  const netCurrent = currentGQLField
    ? calculateNetValue(data, currentGQLField)
    : [];
  const netFuture = calculateNetValue(data, futureGQLField);

  return { netCurrent, netFuture };
};

const calculateNetValue = (data: any, GQLField: string) => {
  const responses = data[GQLField].responses as number[][];
  // Responses are sorted server side
  const badValue = responses[0].map(
    (val: number, idx: number) => val / data.numRespondents[idx]
  );
  const goodValue = responses[2].map(
    (val: number, idx: number) => val / data.numRespondents[idx]
  );

  const netValue = goodValue.map((val: number, idx: number) =>
    formatPercentWithZeroFractionDigits(val - badValue[idx])
  );
  return netValue;
};

export const vcSurveyBySector = (surveyTopic: VCSurveyTopic) =>
  vcSurveyByDimension(surveyTopic, "sector", "Sector");
export const vcSurveyByStageFocus = (surveyTopic: VCSurveyTopic) =>
  vcSurveyByDimension(surveyTopic, "stageFocus", "Stage Focus");
export const vcSurveyByCountryGroup = (surveyTopic: VCSurveyTopic) =>
  vcSurveyByDimension(surveyTopic, "countryGroup", "Country Group");
