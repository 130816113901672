import React from "react";

const TermsOfUse = () => (
  <>
    <h3>TERMS OF USE</h3>
    <em>Last updated on 8th of May, 2019</em>

    <p>
      By using The Bench website (the “Website”), you (if registering as an
      individual or the entity you represent if registering as a
      business/organisation) (hereinafter “you” or “user”) signify your
      understanding and agreement to comply with and be bound by these Terms of
      Use together with the accompanying Privacy Notice and Cookies Policy. This
      Website is owned and maintained by the European Investment Fund (hereafter
      “EIF” or “we”).
    </p>
    <p>
      If you do not agree with the Terms of Use, do not use the Website. We
      reserve the right to revise, update and/or otherwise modify at any time
      and without notice these Terms of Use, including the information, images,
      logos, charts and icons used in the Website. You are encouraged to
      periodically review the Terms of Use, as your continued use of the Website
      indicates your agreement with and acceptance of any such changes to the
      Terms of Use.
    </p>
    <br />
    <br />
    <h3>1. YOUR USE OF THE WEBSITE</h3>
    <p>
      Subject to the creation of a user’s account, you will be provided with
      access to Data (as defined below) on EIF's equity portfolio that will be
      accessible and presented in an aggregated form.
    </p>
    <p>
      Data shall mean any and all of data, information and/or content in any
      form (individually and collectively and any part of it) that you access,
      download or otherwise obtain through the use of the Website (the "Data").
    </p>
    <p>
      We may modify, add or remove Website functionalities, features or
      categories without notice, and we may suspend or stop a functionality
      altogether. You acknowledge that the Website may present Data with
      technical inaccuracies or errors that may be corrected as they are
      discovered, at our sole discretion. Additionally, changes may be
      periodically added to the Data. Such corrections and changes may be
      incorporated into the Website at any time at our sole discretion.
    </p>
    <p>
      You shall not access or collect Data, or attempt to access Data that you
      do not have permission to access, through an automated software or other
      automated means (including, but not limited to, the use of agents, robots,
      spiders, scripts or web crawlers) without prior written permission.
    </p>
    <p>
      You shall not misuse the Website. For the avoidance of doubt, you shall
      not access, collect or use Data from the Website in order to do anything:
    </p>
    <ol type="i">
      <li>
        (i) that breaches or could potentially lead to a breach of these Terms
        of Use;
      </li>
      <li>(ii) that is unlawful, misleading, or fraudulent;</li>
      <li>(iii) that infringes or breaches the rights of a third party.</li>
    </ol>
    <p>
      You are responsible for obtaining, installing, maintaining and utilising
      at your own expense compatible equipment to access the Website and make
      use of the Data.
    </p>
    <br />
    <br />
    <h3>2. INTELLECTUAL PROPERTY</h3>
    <p>
      The Website contains copyrighted content, trademarks and/or other
      proprietary Data (collectively, "Intellectual Property") including but not
      limited to text, logos, tables, charts, data compilations and graphics.
      You acknowledge that you do not acquire any ownership rights by accessing,
      downloading or otherwise using any Data from the Website. We grant you
      merely a limited, non-exclusive, and non-transferable licence, without the
      right to sub-licence, to make use of the Data contained therein.
    </p>
    <p>
      You are allowed to reproduce, reprint or use the Data in the course of
      your ordinary (business) activities to the extent that the Data contained
      in a derivative work of yours is accurate, non-misleading and fully
      consistent with the Data published on the Website, and provided that you
      clearly and unambiguously cite EIF and the Website as the source of the
      Data in the following format: “Source: European Investment Fund – add link
      to the Website”.
    </p>
    <p>
      The licence granted by EIF to you shall terminate if we consider that you
      do not comply with these Terms of Use.
    </p>
    <br />
    <br />
    <h3>3. USER’S ACCOUNT AND SECURITY</h3>
    <p>
      Access to the Website is subject to the creation of an user’s account.
      User’s accounts are personal to each user and multiple users within a
      business/organisation will each require an individual username and
      password.
    </p>
    <p>
      You hereby undertake not to disclose or offer to disclose your username or
      password to any other person, including to any person within your
      business/organisation, and acknowledge that any breach of security
      resulting in the use or attempted use of your username and/or password by
      any other person constitutes a material breach by you of these Terms of
      Use.
    </p>
    <p>
      You acknowledge and agree that you will be liable for, and EIF is entitled
      to a proportionate and adequate compensation from you in respect of, any
      such disclosure or breach of security, except where such disclosure or
      breach of security materialises as a result of loss, theft or other event
      that is outside your control (as demonstrated by you).
    </p>

    <p>
      In the event of loss or theft of a username or password, or in the event
      that you suspect that your user’s username or password has been disclosed
      to any third person, you shall notify us immediately in writing, and shall
      immediately change your password and take all reasonable steps to preserve
      the secrecy of your new password.
    </p>
    <br />
    <br />
    <h3>4. LINKS TO OTHER WEBSITES AND TO THE WEBSITE</h3>
    <p>
      The Website may contain links to other websites, resources and
      advertisers. EIF is not responsible for the availability of these external
      websites nor does it endorse, nor is it responsible for the contents,
      advertising, products or other materials made available on or through such
      websites. Under no circumstances shall EIF be held responsible or liable,
      directly or indirectly, for any loss or damage caused or alleged to have
      been caused in connection with such websites.
    </p>
    <p>
      EIF hereby prohibits the use of the EIF's logo, including as a "hot" link
      to the Website unless the establishment of such a link is approved in
      writing by EIF prior to such use. You may establish a hypertext link to
      the Website from your website provided that:
    </p>
    <ol type="i">
      <li>
        the link must be a text-only link clearly marked “EIF” or “European
        Investment Fund”;
      </li>
      <li>
        the link must be to{" "}
        <a href="https://thebench.eif.org/">https://thebench.eif.org/</a>;
      </li>
      <li>
        the appearance, position and other aspects of the link may not be such
        as to damage or dilute the goodwill associated with EIF's activities,
        name, logo, trademarks;(iv) the appearance, position and other aspects
        of the link may not create the false impression that you or any other
        entity is associated with or sponsored by EIF;
      </li>
      <li>
        the link, when activated by a user, must display the Website full-screen
        and not within a “frame” on the linked website; and
      </li>
      <li>
        EIF reserves the right to revoke its consent to the link at any time at
        its sole discretion.
      </li>
    </ol>

    <br />
    <br />
    <h3>5. DISCLAIMER</h3>
    <p>
      YOU EXPRESSLY AGREE THAT THE USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE
      WEBSITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE EIF MAKES
      NO WARRANTY THAT THE WEBSITE ACCESS WILL BE UNINTERRUPTED, SECURE OR
      ERROR-FREE. NO REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, IS OR WILL
      BE MADE AND NO LIABILITY OR RESPONSIBILITY IS OR WILL BE ACCEPTED BY EIF
      IN RESPECT OF THE ACCURACY OR COMPLETENESS OF ANY DATA AND ANY SUCH
      LIABILITY IS EXPRESSLY DISCLAIMED. THE EIF IS NOT RESPONSIBLE FOR ANY
      ERRORS OR OMISSIONS, OR FOR THE RESULTS OBTAINED FROM THE USE OF SUCH
      DATA.
    </p>
    <p>
      FURTHERMORE, THE WEBSITE AND ANY DATA SHALL NOT BE CONSTRUED AS A
      SOLICITATION OR OFFER, OR RECOMMENDATION TO ACQUIRE OR DISPOSE OF ANY
      INVESTMENT OR TO ENGAGE IN ANY OTHER TRANSACTION WHATSOEVER. EIF SHALL NOT
      BE HELD RESPONSIBLE FOR YOUR USE OF THE DATA AND THE DATA SHOULD AT ALL
      TIMES BE CONSIDERED PROVIDED TO YOU FOR INFORMATION PURPOSES ONLY. IN
      ADDITION, NOTHING IN THE WEBSITE AND THE DATA CONSTITUTES OR SHOULD BE
      CONSTRUED AS AN INVESTMENT, LEGAL, TAX OR OTHER ADVICE OF ANY NATURE
      WHATSOEVER, NOR SHALL BE RELIED UPON AS SUCH ADVICE IN MAKING AN
      INVESTMENT OR OTHER DECISION. SPECIFIC AND INDEPENDENT PROFESSIONAL ADVICE
      SHOULD ALWAYS BE SOUGHT SEPARATELY BEFORE TAKING ANY ACTION BASED ON THE
      DATA ACCESSED OR COLLECTED BY THE WEBSITE.
    </p>
    <br />
    <br />
    <h3>6. LIMITATION OF LIABILITY</h3>
    <p>
      IN NO EVENT WILL EIF BE LIABLE TO YOU FOR ANY DAMAGES OR LOSSES WHICH
      ARISE OUT OF OR ARE IN ANY WAY CONNECTED TO YOUR USE OF THE WEBSITE OR THE
      DATA, EXCEPT IN THE EVENT OF GROSS NEGLIGENCE OR WILFUL MISCONDUCT. FOR
      THE AVOIDANCE OF DOUBT, EIF SHALL UNDER NO CIRCUMSTANCES WHATSOEVER BE
      LIABLE TO YOU FOR CONSEQUENTIAL DAMAGES, LOSS OF PROFIT OR EXEMPLARY OR
      PENAL DAMAGES.
    </p>
    <br />
    <br />
    <h3>7. INDEMNITY</h3>
    <p>
      YOU AGREE TO FULLY INDEMNIFY AND HOLD EIF, ITS CHIEF OFFICERS,
      SHAREHOLDERS, PARENT ENTITIES, SUCCESSORS IN INTEREST, EMPLOYEES, AGENTS,
      SUBSIDIARIES AND AFFILIATES, HARMLESS FROM ANY DEMANDS, LOSSES, LIABILITY,
      CLAIMS OR EXPENSES (INCLUDING LITIGATION COST AND LEGAL FEES), MADE
      AGAINST EIF BY ANY THIRD PARTY DUE TO OR ARISING OUT OF OR IN CONNECTION
      WITH YOUR USE OF THE WEBSITE AND/OR OF THE DATA. IN THE EVENT OF ANY
      ACTION BROUGHT BY A THIRD PARTY AGAINST EIF IN CONNECTION WITH YOUR USE OF
      THE WEBSITE AND/OR THE DATA, YOU SHALL ASSIST EIF IN DEFENDING SUCH
      ACTION.
    </p>
    <br />
    <br />
    <h3>8. USER’S ACCOUNT DELETION</h3>
    <p>
      You may discontinue using the Website at any time by submitting a written
      request for the deletion of your user’s account at{" "}
      <a href="mailto:thebench@eif.org">thebench@eif.org</a>.{" "}
    </p>
    <p>
      If we determine that you have breached or potentially breached these Terms
      or Use, we may delete your user’s account at our discretion. We may also
      delete your user’s account immediately and without notice if required to
      do so by law, law enforcement or other government agencies.
    </p>
    <p>
      Upon deletion of your user’s account, these Terms of Use shall terminate
      as an agreement between you and us, with the exception of the following
      clauses that shall survive the termination: clauses 5, 6, 7, 9 and 10.
    </p>
    <br />
    <br />
    <h3>9. GOVERNING LAW AND JURISDICTION</h3>
    <p>
      These Terms of Use are governed by and construed in accordance with the
      laws of the Grand Duchy of Luxembourg. You agree that any claims, actions
      or proceedings of whatever nature relating to your use of the Website and
      Data shall be subject to the exclusive jurisdiction of the Tribunal
      d'Arrondissement of Luxembourg.
    </p>
    <br />
    <br />
    <h3>10. MISCELLANEOUS</h3>
    <p>
      These Terms of Use constitute the entire agreement between you and EIF
      regarding your use of the Website and the Data. They supersede any prior
      agreements and communication.
    </p>
    <p>
      In the event any of these Terms of Use is deemed invalid, void, or
      unenforceable, that term will be deemed severable and will not affect the
      validity and enforceability of any remaining terms. If we fail to enforce
      any of these Terms of Use, it shall not be considered a waiver. Any
      amendment to or waiver of these Terms of Use shall be made in writing and
      signed. If you breach these Terms of Use and we take no action, we will
      still be entitled to use our rights and remedies in any other situation
      where you breach these Terms of Use.
    </p>
    <p>
      These Terms of Use do not confer any rights on third parties. You shall
      not transfer any of your rights or obligations under these Terms of Use to
      a third party.
    </p>
    <p>
      All of our rights and obligations under these Terms of Use are freely
      assignable by EIF in connection with a merger, acquisition, sale of
      assets, liquidation, by operation of law or otherwise. Upon such
      assignment, EIF shall be relieved of any further obligation hereunder.
    </p>
    <p>
      Nothing in this agreement shall constitute and be construed as a
      partnership, joint venture or agency relationship between the EIF and you.
    </p>
    <p>
      We reserve and retain all rights not expressly granted to you under these
      Terms of Use.
    </p>
  </>
);

export default TermsOfUse;
