export const toFormData = (object: any = {}) =>
  Object.keys(object).reduce((formData, key) => {
    if (object.hasOwnProperty(key)) {
      formData.append(key, object[key]);
    }
    return formData;
  }, new FormData());

export const checkValidity = (value: string, rules: any) => {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  if (!rules) {
    return true;
  }
  if (rules.required && value.trim() === "") {
    return false;
  }
  if (rules.isEmail && !pattern.test(value)) {
    return false;
  }
  return true;
};

export const defaultInputState = {
  touched: false,
  valid: false,
  validation: {
    required: true,
  },
  value: "",
};
